import React, {Component} from 'react';
import BlogFeed from "../Components/Blog/BlogFeed";
import RandomItem from "../Components/Items/RandomItem";
import {Link} from "react-router-dom";
import YouTube from "react-youtube";
import GoogleAd from "../Components/GoogleAd";
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
// import Countdown from "react-countdown";

class YouTubeThumbnail extends Component {
    state = {
        loading: true,
        data: null,
        opts: {
            width: "100%",
            height: "170px",
            playerVars: {
                rel: 0,
                controls: 0,
                showinfo: 0
            }
        }
    }

    async componentDidMount() {
        const url = API_URL + "/api/spotlight/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.result, loading: false})
    }

    render() {
        return (
            <>
                <h3><i className="lightbulb icon"/> Community Spotlight</h3>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            <div style={{marginLeft: "auto", marginRight: "auto", height: "170px", maxHeight: "170px"}}>
                                <YouTube videoId={this.state.data} opts={this.state.opts} onReady={this._onReady}/>
                            </div>
                        </>
                    )
                }
                <div className="rap-link">
                    <a href="/rent-a-plot">
                        <i className="comments dollar icon"/> Rent-a-plot
                    </a>
                </div>
            </>
        )
    }
}
//
// const Completionist = () => (
//     <>
//         <div className="ui segment center aligned">
//             <h1 className="ui center aligned icon header">
//                 <i className="circular birthday cake icon"/> 🎉🎉 Hydroneer 1.6 is Live! 🎉🎉
//                 <div className="sub header">Check out the <a href="/patch-notes">Patch Notes</a>!</div>
//             </h1>
//         </div>
//     </>
// );
//
// const renderer = ({days, hours, minutes, seconds, completed}) => {
//     if (completed) {
//         return <Completionist/>;
//     } else {
//         return (
//             <>
//                 <div className="ui message inverted center aligned">
//                     <p>1.6 is coming! January 15th 2021 at 18:00 GMT</p>
//                 </div>
//                 <div className={`ui ${(days >= 1) ? 'four' : 'three'} column center aligned grid`}>
//                     {(days >= 1) && (
//                         <div className="column ">
//                             <div className="ui segment center aligned">
//                                 <h1 className="ui header">
//                                     {days}
//                                     <div className="sub header">Day{(days !== 1) ? 's' : ''}</div>
//                                 </h1>
//                             </div>
//                         </div>
//                     )}
//                     <div className="column">
//                         <div className="ui segment center aligned">
//                             <h1 className="ui header">
//                                 {hours}
//                                 <div className="sub header">Hour{(hours !== 1) ? 's' : ''}</div>
//                             </h1>
//                         </div>
//                     </div>
//                     <div className="column">
//                         <div className="ui segment center aligned">
//                             <h1 className="ui header">
//                                 {minutes}
//                                 <div className="sub header">Minute{(minutes !== 1) ? 's' : ''}</div>
//                             </h1>
//                         </div>
//                     </div>
//                     <div className="column">
//                         <div className="ui segment center aligned">
//                             <h1 className="ui header">
//                                 {seconds}
//                                 <div className="sub header">Second{(seconds !== 1) ? 's' : ''}</div>
//                             </h1>
//                         </div>
//                     </div>
//                 </div>
//             </>
//         );
//     }
// };

export default class Home extends React.Component {
    render() {

        return (
            <div className="ui container">
                {/*<Countdown date={'2022-01-15T18:00:00Z'} renderer={renderer}/>*/}

                <div className="Page-header">
                    <h1>
                        Welcome to Bridgepour, The Official Hydroneer Wiki
                        <div className="sub-heading">Bridgepour Wiki is The Official <Link
                            to="/about-hydroneer">Hydroneer</Link> Wiki.
                            Bridgepour Wiki aims to take the role of a tour guide to help players, both new and old,
                            make their time within the world of Hydroneer less of a rush and more of an ease. Bridgepour
                            Wiki (the Hydroneer Wiki) will bring together a tonne of how-to guides, item database, and
                            various calculators.<br/>
                            <br/>
                            <small>
                                Hydroneer is a game developed by&nbsp;
                                <a href="http://foulballhangover.com" rel="noopener noreferrer" target="_blank">
                                    Foulball Hangover
                                </a>
                            </small>
                        </div>
                    </h1>
                </div>

                <div className="ui stackable four column center aligned grid">
                    <div className="column">
                        <div className="ui segment">
                            <h2 className="ui center aligned icon header">
                                <a href="/database"><i className="circular database icon"/></a>
                                Items
                            </h2>
                            <p>Take a look at the items in our Hydroneer wiki item database.</p>
                            <a href="/database" className="ui button brand block fluid">View the database</a>
                        </div>
                    </div>
                    <div className="column">
                        <div className="ui segment">
                            <h2 className="ui center aligned icon header">
                                <a href="/guide-book/towns"><i className="circular map icon"/></a>
                                Towns
                            </h2>
                            <p>Check out the 4 main areas within the world of Hydroneer.</p>
                            <a href="/guide-book/towns" className="ui button brand block fluid">Visit Locations</a>
                        </div>
                    </div>
                    <div className="column">
                        <div className="ui segment">
                            <h2 className="ui center aligned icon header">
                                <a href="/guide-book"><i className="circular book icon"/></a>
                                Guides
                            </h2>
                            <p>Learn how to play Hydroneer with our handy user created guides.</p>
                            <a href="/guide-book" className="ui button brand block fluid">Learn Hydroneer</a>
                        </div>
                    </div>
                    <div className="column">
                        <div className="ui segment">
                            <h2 className="ui center aligned icon header">
                                <a href="/mods"><i className="circular wrench icon"/></a>
                                Mods
                            </h2>
                            <p>Mods are a fan favourite within the game Hydroneer, get clued up!</p>
                            <a href="/mods" className="ui button brand block fluid">Explore Mods</a>
                        </div>
                    </div>
                </div>

                <div className="ui stackable two column grid">
                    <div className="eleven wide column">
                        <h2 title="The Hydroneer Wiki"><i className="newspaper icon"/> Latest on Bridgepour Wiki</h2>
                        <BlogFeed/>
                    </div>
                    <div className="sidebar five wide column">
                        <YouTubeThumbnail videoId="6BBfj8Vfccw"/>

                        <GoogleAd slot="2735059186"/>

                        <h2><i className="dice d20 icon"/> Random Item</h2>
                        <RandomItem/>

                        <h2><i className="discord icon"/> Official Discord
                            <a className="ui mini brand button right floated"
                               href="http://discord.com/invite/hgYBTGP" target="_blank" rel="noreferrer"><i
                                className="external link icon"/> Join</a>
                        </h2>
                        <iframe src="https://discordapp.com/widget?id=383329651910639628&theme=dark" width="100%"
                                height="300"
                                frameBorder="0" title="Discord Widget"
                                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"/>
                    </div>
                </div>
            </div>
        )
    }

    _onReady(event) {
        event.target.pauseVideo();
    }
}