import React from "react";
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import {Link} from "react-router-dom";
import queryString from 'query-string';
import {safeName} from "../Utils/Utils";

const item_keys = ["id","name","value","description","resource","blueprint","modded","relic","alias","safe_name","active"];
const store_keys = ["id","town_id","safe_name","name","sells_blueprints","category","inventory","description","alias","modded","active"];
const town_keys = ["town_id","town_safe_name","name","description","alias","modded","active"];
const digsite_keys = ["id","name","closest_town","closest_jeweler","closest_equipment_store","description","price","relics","depth","alias","modded","active"];
const patchnote_keys = ["id","summary","details","active","timestamp"];
const blogpost_keys = ["id","title","safe_summary","html_body","author","thumbnail","date","active"];
const update_keys = ["id","date","title","content","timestamp","active"];
const mod_keys = ["id","name","description","author","added_by","url","timestamp","updated","active"];

export default class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            query: queryString.parse(this.props.location.search).q
        }
    }

    async componentDidMount() {
        if (this.state.query) {
            const url = API_URL + `/api/query/${this.state.query.replace(/\s+/g, '-').replace(/,/g, "").toLowerCase()}/`;
            const response = await fetch(url);
            const data = await response.json();

            this.setState({
                loading: false,
                data: data
            })
        } else {
            this.setState({
                loading: false,
                data: []
            })
        }
    }

    addDefaultSrc = (e) => (
        e.target.src = `/assets/images/items/thumbs/no_image.png`
    )

    render() {
        return (
            <div className="ui container">
                <h3>Search the database</h3>
                <p>Enter your query to search; Items, Shops, Towns and Digsites.</p>

                <form className="search-input-max">
                    <div className="ui action fluid input">
                        <input type="text" name="q" placeholder={this.state.query} />
                        <button className="ui icon brand button">
                            <i className="search icon" />
                        </button>
                    </div>
                </form>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            {this.state.query && (
                                <div className="ui segment">
                                    <p>Your search for "<em>{this.state.query}</em>" returned {this.state.data.count} results.</p>
                                    {this.state.data.success && (
                                        <table className="ui table basic inverted striped">
                                                {this.state.data.results.map(r => (
                                                    <tbody>
                                                    {Object.keys(r).toString() === item_keys.toString() && (
                                                        <tr key={safeName(r.name)}>
                                                            <td width="50px" style={{textAlign: "center"}}>
                                                                <img onError={this.addDefaultSrc} src={"/assets/images/items/thumbs/" + safeName(r.name) + ".png"} alt={r.name}/>
                                                            </td>
                                                            <td>
                                                                <p key={safeName(r.name)}><Link to={"/link/"+ safeName(r.name)}>{r.name}</Link></p>
                                                            </td>
                                                            <td>Item</td>
                                                        </tr>
                                                    )}
                                                    {Object.keys(r).toString() === store_keys.toString() && (
                                                        <tr key={safeName(r.name || r.title)}>
                                                            <td width="50px" style={{textAlign: "center"}}><img src="/assets/images/items/thumbs/no_image.png" alt={r.name}/></td>
                                                            <td>
                                                                <p key={safeName(r.name || r.title)}><a href={"/guide-book/towns/show/" + r.safe_name + "/" + r.id}>{r.name}</a></p>
                                                            </td>
                                                            <td>Store</td>
                                                        </tr>
                                                    )}
                                                    {Object.keys(r).toString() === town_keys.toString() && (
                                                        <tr key={safeName(r.name || r.title)}>
                                                            <td width="50px" style={{textAlign: "center"}}><img src="/assets/images/items/thumbs/no_image.png" alt={r.name}/></td>
                                                            <td>
                                                                <p key={safeName(r.name || r.title)}><a href="/guide-book/towns">{r.name}</a></p>
                                                            </td>
                                                            <td>Town</td>
                                                        </tr>
                                                    )}
                                                    {Object.keys(r).toString() === digsite_keys.toString() && (
                                                        <tr key={safeName(r.name)}>
                                                            <td width="50px" style={{textAlign: "center"}}><img src="/assets/images/items/thumbs/no_image.png" alt={r.name}/></td>
                                                            <td>
                                                                <p key={safeName(r.name)}><a href="/guide-book/digsites/">{r.name}</a></p>
                                                            </td>
                                                            <td>Digsite</td>
                                                        </tr>
                                                    )}
                                                    {Object.keys(r).toString() === patchnote_keys.toString() && (
                                                        <tr key={safeName(r.summary)}>
                                                            <td width="50px" style={{textAlign: "center"}}><img src="/assets/images/items/thumbs/no_image.png" alt={r.summary}/></td>
                                                            <td>
                                                                <p key={safeName(r.summary)}><a href={"/patch-notes/"+ r.id}>{r.summary}</a></p>
                                                            </td>
                                                            <td>Patchnote</td>
                                                        </tr>
                                                    )}
                                                    {Object.keys(r).toString() === blogpost_keys.toString() && (
                                                        <tr key={safeName(r.title)}>
                                                            <td width="50px" style={{textAlign: "center"}}><img src="/assets/images/items/thumbs/no_image.png" alt={r.title}/></td>
                                                            <td>
                                                                <p key={safeName(r.title)}><Link to={"/blog/" + r.id}>{r.title}</Link></p>
                                                            </td>
                                                            <td>Blogpost</td>
                                                        </tr>
                                                    )}
                                                    {Object.keys(r).toString() === update_keys.toString() && (
                                                        <tr key={safeName(r.title)}>
                                                            <td width="50px" style={{textAlign: "center"}}><img src="/assets/images/items/thumbs/no_image.png" alt={r.title}/></td>
                                                            <td>
                                                                <p key={safeName(r.title)}><Link to="/about/">{r.title}</Link></p>
                                                            </td>
                                                            <td>Bridgepour Update</td>
                                                        </tr>
                                                    )}
                                                    {Object.keys(r).toString() === mod_keys.toString() && (
                                                        <tr key={safeName(r.name)}>
                                                            <td width="50px" style={{textAlign: "center"}}><img src="/assets/images/items/thumbs/no_image.png" alt={r.name}/></td>
                                                            <td>
                                                                <p key={safeName(r.name)}><Link to={"/legacy-mods?mod="+ r.name}>{r.name}</Link></p>
                                                            </td>
                                                            <td>Mod</td>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                ))}
                                        </table>
                                    )}
                                </div>
                            )}
                        </>
                    )
                }
            </div>
        )
    }
}