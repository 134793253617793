import React from 'react';
import Loader from "../../Utils/Loader";
import {API_URL} from "../../Utils/Config";
import ItemCard from "../Items/ItemCard";
import ItemTooltip from "../../Utils/ItemTooltip";
import {safeName} from "../../Utils/Utils";
import VehicleList from "./VehicleList";

export default class ShopView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            shop_id: props.match.params.shop_id,
            data: null
        }
    }

    async componentDidMount() {
        const url = API_URL + "/api/shop/" + (this.state.shop_id) + "/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results[0], loading: false})
    }

    render() {
        return (<>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <div className="ui container">
                            <div className="ui breadcrumb inverted">
                                <a href="/" className="section">Bridgepour Wiki Home</a>
                                <i className="right angle icon divider"/>
                                <a href="/guide-book" className="section">Guide Book</a>
                                <i className="right angle icon divider"/>
                                <a href="/guide-book/towns" className="section">Towns</a>
                                <i className="right angle icon divider"/>
                                <div className="section">{this.state.data.town.name}</div>
                                <i className="right angle icon divider"/>
                                <div className="active section">{this.state.data.name}</div>
                            </div>

                            <h1>
                                {this.state.data.name}
                            </h1>

                            {this.state.data.modded && (
                                <p className="ui message inverted"><i
                                    className="info circle icon"/> {this.state.data.description}</p>
                            )}

                            {this.state.data.sells_blueprints &&
                            <div className="ui segment">
                                <i className="info circle icon"/> This store also stocks blueprints, scroll down to take
                                a look!
                            </div>
                            }

                            {(["store", "stocks", "jeweler", "carts", "logic-power"]).includes(this.state.data.category) &&
                            <>
                                {this.state.data.description !== null && (
                                    <p className="ui message inverted">{this.state.data.description}</p>
                                )}

                                <p>
                                    This store
                                    {(["store", "carts", "logic-power"]).includes(this.state.data.category) &&
                                    <span> sells </span>}
                                    {(["stocks", "jeweler"]).includes(this.state.data.category) && <span> buys </span>}
                                    the following items:
                                </p>

                                <div className="ui link four cards stackable">
                                    {this.state.data.inventory.map(item => (
                                        <a className="ui card" key={safeName(item.name)}
                                           href={"/database/" + safeName(item.name)}>
                                            <div className="image">
                                                <img onError={this.addDefaultSrc}
                                                     src={"/assets/images/items/" + safeName(item.name) + ".png"}
                                                     alt={item.name}
                                                />
                                            </div>
                                            <div className="content">
                                                <div className="header">
                                                    {item.name}
                                                </div>
                                                <div className="meta">
                                                    {item.modded &&
                                                    <><i className="wrench icon"/> Modded</>
                                                    }
                                                </div>
                                                {item.description && (
                                                    <div className="description">
                                                        {item.description}
                                                    </div>
                                                )}
                                            </div>
                                            {(!this.props.hide_details || item.value) && (
                                                <div className="extra content">
                                                    <table className="ui table very basic inverted unstackable">
                                                        {item.value &&
                                                        <tr>
                                                            <td width="40%">Cost</td>
                                                            <td className="text-right">{(item.value / 100).toLocaleString(undefined, {maximumFractionDigits: 0})}c</td>
                                                        </tr>
                                                        }

                                                        {!this.props.hide_details &&
                                                        <tr>
                                                            <td colSpan="2">
                                                                <a href={"/database/" + safeName(item.name)}
                                                                   className="ui brand button mini fluid">
                                                                    Details
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        }
                                                    </table>
                                                </div>
                                            )}
                                        </a>
                                    ))}
                                </div>
                            </>
                            }

                            {this.state.data.category === "legacy" &&
                            <>
                                <p>Whilst this store is no longer in the game, its' memory lives on.</p>
                            </>
                            }

                            {this.state.data.category === "vehicles" &&
                            <>
                                <VehicleList/>
                            </>
                            }

                            {this.state.data.category === "scrapyard" &&
                            <>
                                <p>The Scrapyard buys all construction items in return for <ItemTooltip
                                    item="scrap-metal"/> worth for 50% of the original purchase price.</p>
                                <p>Scrap Metal can also be obtained by blowing construction items up using <ItemTooltip
                                    item="logic-explosives"/>.</p>
                                <p>This can be increased further by smelting Scrap Metal in a <ItemTooltip
                                    item="small-furnace"/>, <ItemTooltip item="smelter"/> or a <ItemTooltip
                                    item="logic-smelter"/>. This yields a <ItemTooltip item="scrap-bar"/> which is worth
                                    20% more than the Scrap Metal, bringing the total return to 60% of the original
                                    construction items' value.</p>

                                <div className="Item-grid">
                                    <ItemCard item="scrap-metal"/>
                                    <ItemCard item="scrap-bar" />
                                </div>
                            </>
                            }

                            {this.state.data.sells_blueprints &&
                            <>
                                <br/>
                                <br/>
                                <h1>Lower Deck</h1>
                                <p>The Lower Deck of The Barge holds the blueprints collection.</p>
                                <div className="Item-grid">
                                    {this.state.data.blueprints.map(item => (
                                        <>
                                            <ItemCard item={item.input} output={item.output}/>
                                        </>
                                    ))}
                                </div>
                            </>
                            }
                        </div>
                    )
                }
            </>
        )
    }

}