import React from 'react';
import {API_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";
import ItemTooltip from "../../Utils/ItemTooltip";
import './DigsiteList.sass'
import {safeName} from "../../Utils/Utils";

export default class DigsiteList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
        }
    }

    async componentDidMount() {
        const url = API_URL + "/api/digsites/all/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})
    }

    render() {
        return (
            <>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <div className="Digsite-list">
                            {this.state.data.map(item => (
                                <div key={item.id} className="Digsite ui segment">
                                    <div className={"Digsite-header "+safeName(item.name)}>
                                        <h3>{item.name}</h3>
                                    </div>
                                    <div className="Digsite-description">
                                        {item.description}
                                    </div>
                                    <div className="ui middle aligned divided list stackable">
                                        <div className="digsite-item">
                                            Cost:
                                            <span className="value">
                            {item.price.toLocaleString(undefined, {maximumFractionDigits: 0})}c
                        </span>
                                        </div>
                                        <div className="digsite-item">
                                            Closest town:
                                            <span className="value">
                                    {item.town.name}
                        </span>
                                        </div>
                                        <div className="digsite-item">
                                            Depth:
                                            <span className="value">
                                                {item.depth} Blocks
                                            </span>
                                        </div>
                                        <div className="digsite-item">
                                            Equipment:
                                            <span className="value">
                            <a href={"/guide-book/towns/" + item.town.town_safe_name + "/" + item.equipment_store.safe_name + "/" + item.equipment_store.id}>{item.equipment_store.name}</a>
                        </span>
                                        </div>
                                        <div className="digsite-item">
                                            Jeweler:
                                            <span className="value">
                            <a href={"/guide-book/towns/" + item.town.town_safe_name + "/" + item.jeweler.safe_name + "/" + item.jeweler.id}>{item.jeweler.name}</a>
                        </span>
                                        </div>
                                    </div>
                                    <details>
                                        <summary>Show Relics</summary>
                                        <div>
                                            <div className="digsite-relic-list">
                                                {item.relics.map(r => (
                                                    <ItemTooltip key={r} item={r}/>
                                                ))}
                                            </div>
                                            <details>
                                                <summary>Show Relic Locations</summary>
                                                <div>
                                                    <img alt={item.name}
                                                         src={`/assets/images/relics-${safeName(item.name)}.jpg`}
                                                         width="100%"/>
                                                </div>
                                            </details>
                                        </div>
                                    </details>
                                </div>
                            ))}
                        </div>
                    )
                }
            </>
        )
    }
}