import React from 'react';
import PostSummary from "./PostSummary";
import {API_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";

export default class BlogAll extends React.Component {
    state = {
        loading: true,
        data: null
    }

    async componentDidMount() {
        const url = API_URL + "/api/blogposts/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})
    }

    render() {
        return (
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Blog</div>
                </div>

                <br/>
                <br/>

                <div className="Page-header">
                    <h1>
                        Bridgepour Blog
                    </h1>
                </div>

                <div className="Blog-feed">
                    {this.state.loading || !this.state.data ?
                        (
                            <Loader/>
                        ) : (
                            <>
                                {this.state.data.map(data => (
                                    <PostSummary post={data} key={data.title}/>
                                ))}
                            </>
                        )
                    }
                </div>
            </div>
        )
    }
}