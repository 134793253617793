import React from 'react';

export default function Guidebook() {
    return (
        <>
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Guide Book</div>
                </div>

                <div className="ui three columns stackable grid homepage-boxes">
                    <div className="column">
                        <div className="ui segment">
                            <h2 className="ui center aligned icon header">
                                <a href="/database"><i className="circular database icon"/></a>
                                Items
                            </h2>
                            <p>Take a look at the items in our Hydroneer wiki item database.</p>
                            <a href="/database" className="ui button brand block fluid">View the database</a>
                        </div>
                    </div>
                    <div className="column">
                        <div className="ui segment">
                            <h2 className="ui center aligned icon header">
                                <a href="/guide-book/towns"><i className="circular map icon"/></a>
                                Towns
                            </h2>
                            <p>Get to know the towns of Hydroneer along with local store inventories and sellables.</p>
                            <a href="/guide-book/towns" className="ui button brand block fluid">Visit Locations</a>
                        </div>
                    </div>
                    <div className="column">
                        <div className="ui segment">
                            <h2 className="ui center aligned icon header">
                                <a href="/guide-book/digsites"><i className="circular dungeon icon"/></a>
                                Digsites
                            </h2>
                            <p>Learn more about digsites, what they do and what their nearest conveniences are.</p>
                            <a href="/guide-book/digsites" className="ui button brand block fluid">Start Digging</a>
                        </div>
                    </div>
                    <div className="sixteen wide column">
                        <h3>Guides</h3>
                        <div className="ui segment">
                            <h3><a href="/guide-book/guide/first-timer"><i className="gifts icon" /> First Timer.</a></h3>
                            <p>If you want to know the pure basics, take a look at this First Timer guide.</p>
                        </div>
                        <div className="ui segment">
                            <h3><a href="/guide-book/guide/getting-started"><i className="egg icon" /> Getting Started.</a></h3>
                            <p>If you're fairly new to Hydroneer, check out our getting started guide here!</p>
                        </div>
                        <div className="ui segment">
                            <h3><a href="/guide-book/guide/hydro-logic/"><i className="brain icon" /> HydroLogic (by Touffu64)</a></h3>
                            <p>In this guide, we will explain HOW the logic works in the game. </p>
                            <p>The goal is that you can have a complete understanding on how the logic signals behaves, and you will see that many weird behaviors will suddenly become a lot more... logic!</p>
                        </div>
                        <div className="ui segment">
                            {/*<h3><a href="/guide-book/guide/smithing-crafting"><i className="hammer icon" /> How to smith and craft items.</a></h3>*/}
                            <h3><i className="hammer icon"/> How to smith and craft items. <span
                                className="muted">Coming soon!</span></h3>
                            <p>So, you've gathered enough ore to start making something fancy!? Check out this
                                smithing and crafting guide!</p>
                        </div>
                        <div className="ui segment">
                            {/*<h3><a href="#"><i className="book icon" /> Researching Blueprints.</a></h3>*/}
                            <h3><i className="book icon"/> Researching Blueprints. <span
                                className="muted">Coming soon!</span></h3>
                            <p>You have explored the depths of various parcels of land, and developed a thirst for
                                more, check out our Blueprints guide!!</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}