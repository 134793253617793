import React from 'react';
import GoogleAd from "../Components/GoogleAd";
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import queryString from 'query-string';

export default class Mods extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            mod_query: queryString.parse(this.props.location.search).mod,
            author_query: queryString.parse(this.props.location.search).author,
            filteredList: null
        }
    }

    async componentDidMount() {
        const url = API_URL + "/api/mods/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})

        if (this.state.mod_query) {
            const filteredMods = this.state.data.filter((mod) => mod.name.toLowerCase().includes(this.state.mod_query.toLowerCase()))
            this.setState({filteredList: filteredMods})
        }

        if (this.state.author_query) {
            const filteredMods = this.state.data.filter((mod) => mod.author.toLowerCase().includes(this.state.author_query.toLowerCase()))
            this.setState({filteredList: filteredMods})
        }
    }

    render() {
        return (
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <a href="/mods/" className="section">Mods</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Legacy Mods</div>
                </div>

                <div className="Page-header">
                    <h1>
                        {!this.state.mod_query ? (
                            <>Legacy Mods
                                <div className="sub-heading">
                                    <p>This page has been made to act as a reference point for legacy mods created pre
                                        Steam
                                        Workshop support.</p>
                                    <p>
                                        To view mods that are in Steam Workshop, go to the main <a
                                        href="/mods">Mods</a> page.
                                    </p>
                                </div>
                            </>
                        ) : (
                            <><span className="muted">Search:</span> {this.state.mod_query}</>
                        )}
                    </h1>
                </div>

                <div className="ui two column doubling grid container">
                    <div className="eleven wide column">
                        {this.state.loading || !this.state.data ?
                            (
                                <Loader/>
                            ) : (
                                <>
                                    {!this.state.filteredList ? (
                                        <>
                                            <div className="ui segment">
                                                <h3>Notable Mods</h3>
                                                <p>Listed below are all {this.state.data.length} verified Hydroneer Mods. Use
                                                    the links provided to get the latest version from the Mod Authors' github.</p>
                                            </div>
                                            <div className="ui one column stackable grid">
                                                {this.state.data.map(mod => (
                                                    <div className="column" key={mod.name}>
                                                        <div className="ui segment">
                                                            <div className="ui two column grid">
                                                                <div className="ten wide column">
                                                                    {mod.name === 'Hydrotility++' ? (
                                                                        <h3 className="header">
                                                                            <a href={"/legacy-mods/" + mod.name}
                                                                               title="View Details">
                                                                                {mod.name}
                                                                            </a>
                                                                            <div
                                                                                className="sub header muted">{mod.author}</div>
                                                                        </h3>
                                                                    ) : (
                                                                        <h3 className="header">
                                                                            {mod.name}
                                                                            <div
                                                                                className="sub header muted">{mod.author}</div>
                                                                        </h3>
                                                                    )}
                                                                    <p>{mod.description}</p>
                                                                </div>

                                                                <div className="six wide column">
                                                                    {mod.ribbon_text !== null && mod.ribbon_text !== 'New!' && (
                                                                        <div className="item disabled"
                                                                             style={{
                                                                                 padding: "0",
                                                                                 marginBottom: "5ch"
                                                                             }}>
                                                                    <span
                                                                        className={"ui right ribbon " + (mod.ribbon_color !== null && (mod.ribbon_color)) + " label"}><i
                                                                        className="tag icon"/> {mod.ribbon_text}</span>
                                                                        </div>
                                                                    )}
                                                                    <div className="ui fluid buttons">
                                                                        {mod.name === 'Hydrotility++' && (
                                                                            <a className="ui brand mini button"
                                                                               href={"/legacy-mods/" + mod.name}
                                                                               style={{marginRight: "1ch"}}>
                                                                                <i className="info icon"/> Details
                                                                            </a>
                                                                        )}
                                                                        <a className="ui brand mini button"
                                                                           href={mod.url}>
                                                                            <i className="download icon"/> Download
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {this.state.filteredList.count !== 0 ? (
                                                <div className="ui one column stackable grid">
                                                    {this.state.filteredList.map(mod => (
                                                        <div className="column" key={mod.name}>
                                                            <div className="ui segment">
                                                                <div className="ui two column grid">
                                                                    <div className="ten wide column">
                                                                        {mod.details ? (
                                                                            <h3 className="header">
                                                                                <a href={"/legacy-mods/" + mod.name}
                                                                                   title="View Details">
                                                                                    {mod.name}
                                                                                </a>
                                                                                <div
                                                                                    className="sub header muted">{mod.author}</div>
                                                                            </h3>
                                                                        ) : (
                                                                            <h3 className="header">
                                                                                {mod.name}
                                                                                <div
                                                                                    className="sub header muted">{mod.author}</div>
                                                                            </h3>
                                                                        )}
                                                                        <p>{mod.description}</p>
                                                                    </div>

                                                                    <div className="six wide column">
                                                                        {mod.ribbon_text !== null && mod.ribbon_text !== 'New!' && (
                                                                            <div className="item disabled"
                                                                                 style={{
                                                                                     padding: "0",
                                                                                     marginBottom: "5ch"
                                                                                 }}>
                                                                    <span
                                                                        className={"ui right ribbon " + (mod.ribbon_color !== null && (mod.ribbon_color)) + " label"}><i
                                                                        className="tag icon"/> {mod.ribbon_text}</span>
                                                                            </div>
                                                                        )}
                                                                        <div className="ui fluid buttons">
                                                                            {mod.details && (
                                                                                <a className="ui brand mini button"
                                                                                   href={"/legacy-mods/" + mod.name}
                                                                                   style={{marginRight: "1ch"}}>
                                                                                    <i className="info icon"/> Details
                                                                                </a>
                                                                            )}
                                                                            <a className="ui brand mini button"
                                                                               href={mod.url}>
                                                                                <i className="download icon"/> Download
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <h3>No items, <a href="/legacy-mods/">go back</a></h3>
                                            )}
                                        </>
                                    )}
                                </>
                            )
                        }
                    </div>
                    <div className="sidebar five wide column">
                        <a href="/mods" className="ui brand button fluid">Steam Workshop Mods</a><br/>
                        <span className="ui brand button fluid disabled">Legacy Mods</span><br/>
                        <a href="/mod-faq" className="ui brand button fluid">View the Mods FAQ</a>

                        <br/>
                        <form action="/legacy-mods" className="ui form">
                            <label for="mod">Find a mod</label>
                            <input type="text" name="mod" />
                        </form>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/kaiheilos" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                Team Hydronix
                            </h3>
                            <div>
                                <p>Team comprised of; Heilos, Rhino, Meaf, NineOClockNate.</p>
                            </div>
                        </div>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/RHlNO/HydroneerModding" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                Rhino
                            </h3>
                        </div>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/rexxar-tc/RexLogic/releases" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                Rexxar
                            </h3>
                        </div>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/Gamerkuipers/Hydroneer-Modding" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                Gamerkuipers
                            </h3>
                        </div>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/ResaloliPT/HydroModTool/releases" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                ResaloliPT
                            </h3>
                        </div>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/VenoM-Chan/HydroneerModding" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                VenoM
                            </h3>
                        </div>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/VegeoStudios/HydroMods" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                Vegeo Studios
                            </h3>
                        </div>

                        <GoogleAd slot="5601721053"/>
                        <br/>
                    </div>
                </div>
            </div>
        )
    }
}