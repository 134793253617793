import React from 'react';

export default function Contact() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <div className="active section">Contact Us</div>
            </div>
            <div className="Page-header">
                <h1>
                    Contact Us
                    <div className="sub-heading">
                        Got something you needed to tell us? Got a cool idea? Let us know!
                    </div>
                </h1>
            </div>

            <div className="ui message inverted">
                These contact methods are for the Wiki / Discord bot only, please use the <a href="https://discord.gg/hydroneer">official Discord server</a> for any bugs / suggestions for the game, Hydroneer.
            </div>

            <div className="ui three column doubling grid container contact-options">
                <div className="column">
                    <div className="ui segment">
                        <i className="list icon" />
                        <br/>
                        <a className="ui brand button" href="https://forms.gle/wDF7UNLX1HjoGKWi9" target="_blank" rel="noopener noreferrer">Submit Feedback / Suggestions</a>
                    </div>
                </div>
                <div className="column">
                    <div className="ui segment">
                        <i className="envelope open text icon" />
                        <br/>
                        <a className="ui brand button" href="mailto:contact@bridgepour.com">contact@bridgepour.com</a>
                    </div>
                </div>
                <div className="column">
                    <div className="ui segment">
                        <i className="twitter icon" />
                        <br/>
                        <a className="ui brand button" href="https://twitter.com/bridgepour" target="_blank" rel="noopener noreferrer">@bridgepour</a>
                    </div>
                </div>
            </div>
        </div>
    )
}