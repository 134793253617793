import React from 'react';
import {numberWithSuffix} from "../Utils/Utils";
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import Moment from "react-moment";

export default class Speedrun extends React.Component {
    state = {
        loading: true,
        query_player: "none",
        query_plot: "none",
        query_version: "none",
        query_challenge: "none",
        query_setup: "none",
        data: [],
        filtered_runs: []
    }

    async componentDidMount() {
        const url = API_URL + "/api/speedruns/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, filtered_runs: data.results, loading: false})
    }

    async filterRows(query_type, e) {
        let query_player = ((query_type === "user") ? e.target.value : this.state.query_player)
        let query_plot = ((query_type === "plot") ? e.target.value : this.state.query_plot)
        let query_version = ((query_type === "version") ? e.target.value : this.state.query_version)
        let query_challenge = ((query_type === "challenge") ? e.target.value : this.state.query_challenge)
        let query_setup = ((query_type === "setup") ? e.target.value : this.state.query_setup)

        const url = API_URL + "/api/speedruns/filter/"+query_player+"|"+query_plot+"|"+query_version+"|"+query_challenge+"|"+query_setup;
        const response = await fetch(encodeURI(url));
        const data = await response.json();
        this.setState({filtered_runs: data.results, loading: false})
    }

    filter_user = (e) => {
        this.setState({loading: true, query_player: e.target.value});
        this.filterRows("user", e);
    }

    filter_plot = (e) => {
        this.setState({loading: true, query_plot: e.target.value});
        this.filterRows("plot", e);
    }

    filter_version = (e) => {
        this.setState({loading: true, query_version: e.target.value});
        this.filterRows("version", e);
    }

    filter_challenge = (e) => {
        this.setState({loading: true, query_challenge: e.target.value});
        this.filterRows("challenge", e);
    }

    filter_setup = (e) => {
        this.setState({loading: true, query_setup: e.target.value});
        this.filterRows("setup", e);
    }

    render() {
        const players = [...new Set(this.state.data.map(q => q.player))];
        const plots = [...new Set(this.state.data.map(q => q.plot))];
        const versions = [...new Set(this.state.data.map(q => q.version))];
        const challenges = [...new Set(this.state.data.map(q => q.challenge))];
        const setups = [...new Set(this.state.data.map(q => q.setup))];

        return (
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Speedrun</div>
                </div>

                <div className="ui segment">
                    <h1><i className="stopwatch icon"/> Speedrun</h1>
                    <p>The filters are populated with runs that exist. If, for example, no speedrun has taken place in Dreck Quarry - Dreck Quarry will not appear in the "Select Plot" dropdown.</p>
                    <p>If you wish to submit a speed run entry, please send a message in the Foulball Hangover Discord and tag @ItchyBeard - with the following information;
                        <ul>
                            <li>Plot: <span className="muted">[Ember Cradle, Dreck Quarry, Mildew's Aquifer, South Hope]</span></li>
                            <li>Time: <span className="muted">01h 02m 03s</span></li>
                            <li>Game Version: <span className="muted">1.5.1</span></li>
                            <li>Challenge: <span className="muted">1M coins (No mods)</span></li>
                            <li>Setup: <span className="muted">D/G</span></li>
                            <li>Video URL: <span className="muted">https://www.youtube.com/watch?v=dQw4w9WgXcQ</span></li>
                        </ul>
                    </p>
                    <p className="ui message inverted">When submitting your run, please state if you used Mods, Unstuck or Glitches. If any items are spawned, your run will be void and will not be added to the leaderboard.</p>
                </div>

                <div className="ui segment">
                    <div className="ui form">
                        <h3>Filter the Speedruns</h3>
                        <div className="five fields">
                            <div className="field">
                                <select className="ui search dropdown" onChange={this.filter_user}>
                                    <option value="none">Select User</option>
                                    {players.map(player => (
                                        <option value={player} selected={this.state.query_player === player}>{player}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="field">
                                <select className="ui search dropdown" onChange={this.filter_plot}>
                                    <option value="none">Select Plot</option>
                                    {plots.map(plot => (
                                        <option value={plot} selected={this.state.query_plot === plot}>{plot}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="field">
                                <select className="ui search dropdown" onChange={this.filter_version}>
                                    <option value="none">Select Game Version</option>
                                    {versions.map(version => (
                                        <option value={version} selected={this.state.query_version === version}>{version}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="field">
                                <select className="ui search dropdown" onChange={this.filter_challenge}>
                                    <option value="none">Select Challenge</option>
                                    {challenges.map(challenge => (
                                        <option value={challenge} selected={this.state.query_challenge === challenge}>{challenge}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="field">
                                <select className="ui search dropdown" onChange={this.filter_setup}>
                                    <option value="none">Select Setup</option>
                                    {setups.map(setup => (
                                        <option value={setup} selected={this.state.query_setup === setup}>{setup}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <table className="ui table basic inverted striped">
                        <thead>
                        <tr>
                            <th width="32px">Rank</th>
                            <th>Player</th>
                            <th>Time</th>
                            <th>Challenge</th>
                            <th>Setup</th>
                            <th>Version</th>
                            <th>Submitted</th>
                            <th style={{textAlign: "center"}}><i className="ui video camera icon" /></th>
                        </tr>
                        </thead>
                        <tbody>
                        <>
                            {this.state.loading || !this.state.filtered_runs ?
                                (
                                    <tr>
                                        <td colSpan="8"><Loader/></td>
                                    </tr>
                                ) : (
                                    <>
                                        {this.state.filtered_runs.length === 0 ? (
                                            <>
                                                <tr>
                                                    <td colSpan="8">Sorry, no results for that selection.</td>
                                                </tr>
                                            </>
                                        ): (
                                            <>
                                                {this.state.filtered_runs.map((run,index) => (
                                                    <>
                                                        <tr>
                                                            <td style={{textAlign: "center"}}>
                                                                {numberWithSuffix(index+1)}
                                                            </td>
                                                            <td>{run.player}</td>
                                                            <td>{run.time}</td>
                                                            <td>{run.plot} - {run.challenge}</td>
                                                            <td>{run.setup}</td>
                                                            <td>{run.version}</td>
                                                            <td><Moment fromNow date={run.timestamp} utc withTitle titleFormat="dddd, DD MMMM YYYY" className="Blog-post-timestamp" /></td>
                                                            <td className="selectable" style={{textAlign: "center"}}><a href={run.videolink} rel="noopener noreferrer" target="_blank"><i className={"ui icon " + run.videoProvider}/></a></td>
                                                        </tr>
                                                    </>
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}