import React from 'react';
import Loader from "../../Utils/Loader";
import {API_URL} from "../../Utils/Config";

export default class Database extends React.Component {
    state = {
        loading: true,
        data: null,
        show_mods: false
    }

    componentWillMount() {
        const new_mod_state = localStorage.getItem('show_mods') === 'true';
        this.setState({show_mods: new_mod_state})
    }

    async componentDidMount() {
        const url = API_URL + "/api/towns/" + (this.state.show_mods ? "all" : "vanilla") + "/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})
    }


    render() {
        return (<>
                <div className="ui container">
                    <div className="ui breadcrumb inverted">
                        <a href="/" className="section">Bridgepour Wiki Home</a>
                        <i className="right angle icon divider"/>
                        <a href="/guide-book" className="section">Guide Book</a>
                        <i className="right angle icon divider"/>
                        <div className="active section">Towns</div>
                    </div>
                    {this.state.loading || !this.state.data ?
                        (
                            <Loader/>
                        ) : (
                            <div className="Town-list">
                                {this.state.data.map(town => (
                                    <div key={town.id} className={"ui segment " + town.town_safe_name}>
                                        <h3>
                                            {town.name}
                                        </h3>

                                        <p>{town.description}</p>

                                        <h5>Notable Locations</h5>
                                        <div className="button-grid">
                                            {town.shops.map(shop => (
                                                <>
                                                    {!shop.modded && (
                                                        <a href={"/guide-book/towns/" + town.town_safe_name + "/" + shop.safe_name + "/" + shop.id}
                                                           className="column four wide ui brand button"
                                                           key={shop.shop_id}>
                                                            {shop.name}

                                                            {(["logic-power", "carts"]).includes(shop.category) && (
                                                                <span className="label">
                                                                    <i className="certificate icon"/> NEW
                                                                </span>
                                                            )}
                                                        </a>
                                                    )}
                                                    {this.state.show_mods && (
                                                        <>
                                                            {shop.modded && (
                                                                <>
                                                                    <a href={"/guide-book/towns/" + town.town_safe_name + "/" + shop.safe_name + "/" + shop.id}
                                                                       className="column four wide ui brand button"
                                                                       key={shop.shop_id}
                                                                       title="This shop is added in via a mod.">
                                                                        <i className="wrench icon"/> {shop.name}
                                                                    </a>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }
                </div>
            </>
        )
    }
}