import React from "react";
import {API_URL} from "./Config";
import Loader from "./Loader";

export default class WeightValueCalculator extends React.Component {
    constructor({props, item}) {
        super(props);
        this.state = {
            base_weight: 1,
            base_value: 1,
            weight: 1,
            value: 1,
            item: item,
            data: null,
            loading: true
        }
    }

    async componentDidMount() {
        const url = API_URL + `/api/resources/${this.state.item}/`;
        console.log(url)
        const response = await fetch(url);
        const data = await response.json();
        this.setState({
            data: data.results[0],
            base_value: data.results[0].value,
            value: data.results[0].value,
            loading: false
        })
    }

    apply_weight = (e) => {
        this.setState({
            weight: e.target.value,
            value: (this.state.base_value * e.target.value).toFixed(2)
        })
    }

    apply_value = (e) => {
        this.setState({
            value: e.target.value,
            weight: (e.target.value / this.state.base_value).toFixed(2)
        })
    }

    render() {
        return (
            <>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <div className="ui segment">
                            <p>Quickly calculate <strong>weight:value</strong></p>
                            <form className="ui form">
                                <div className="field">
                                    <div className="two fields">
                                        <div className="field">
                                            <label>
                                                <i className="dumbbell icon"/> Weight
                                            </label>
                                            <input type="number" value={this.state.weight} id="weight"
                                                   onChange={this.apply_weight}/>
                                        </div>
                                        <div className="field">
                                            <label>
                                                <i className="coins icon"/> Value
                                            </label>
                                            <input type="number" value={this.state.value} id="value" onChange={this.apply_value}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )
                }
            </>
        )
    }
}