import React from 'react';

export default class RentAPlot extends React.Component {
    render() {
        return (
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Rent-a-plot</div>
                </div>

                <div className="ui segment">
                    <h1><i className="comments dollar icon"/> Rent-a-plot</h1>
                    <p className="text-lead">The "Rent-a-plot" scheme was set up as a way to help pay for maintenance
                        and hosting costs for Bridgepour Wiki (the Hydroneer Wiki) and to reduce the amount of external
                        advertising, which does not fit in with the style of Bridgepour. This scheme helps promote
                        Hydroneer content creators by bringing them to the front page of the Hydroneer Wiki, which is
                        visited daily by hundreds of users.</p>
                    <p>The premise is that a content creator can pay a small amount to "rent" the plot on the homepage
                        of the wiki for 1 (one) week at a time. Rent cannot be paid for multiple weeks at a time. This
                        is to allow other content creators to be seen. If at the end of the week the content creator
                        wants to continue and no other content creator has shown interest, then you may be offered to
                        continue to pay rent.</p>
                    <p>Rent is paid upfront before your week begins and is non-refundable.</p>
                    <p>I do not, and will not, make any personal gain from this scheme, any money invested will go
                        towards the monthly maintenance and hosting costs of the Bridgepour Wiki (the Hydroneer
                        Wiki) and potentially contribute to any contest / competition prize(s)
                        that <strong><u>may</u></strong> be given in the future.</p>
                    <p>This scheme is run under my discretion, I reserve the right to remove any content at any
                        time (if needed). I will endeavor to provide reasoning before hand, or shortly after, if before
                        is not possible.</p>
                    <p>If you would like to enquire about rent-a-plot, please send details of your channel and (if
                        available) a link to the video you wish to promote, via email to: <a
                            href="mailto:contact@bridgepour.com?subject=rent-a-plot">contact@bridgepour.com</a>.</p>
                    <p className="ib">
                        ItchyBeard
                    </p>
                </div>
            </div>
        )
    }
}