import React from 'react';
import {API_URL} from "./Config";
import Loader from "./Loader";
import ItemCard from "../Components/Items/ItemCard";
import './ItemTooltip.sass';
import {safeName} from "./Utils";

export default class ItemTooltip extends React.Component {
    constructor({props, item}) {
        super(props);
        this.state = {
            item: item,
            data: null,
            isShown: false
        }
    }

    async componentDidMount() {
        const url = API_URL + `/api/items/exact/${this.state.item}/`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({
            data: data.results[0],
            loading: false
        })
    }

    render() {
        return (
            <>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <span className="tooltip-container">
                            <a href={"/database/" + safeName(this.state.data.name)}
                               onMouseEnter={() => this.setState({isShown: true})}
                               onMouseLeave={() => this.setState({isShown: false})}>
                                {this.state.data.name}
                            </a>
                            {this.state.isShown && (
                                <div className="tooltip"
                                     onMouseEnter={() => this.setState({isShown: true})}
                                     onMouseLeave={() => this.setState({isShown: false})}>
                                    <ItemCard item={safeName(this.state.data.name)} hide_details />
                                </div>
                            )}
                        </span>
                    )
                }
            </>
        )
    }
}
