import React from 'react';
import YouTube from 'react-youtube';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

import image1 from './slides/SC1.png';
import image2 from './slides/SC2.png';
import image3 from './slides/SC3.png';
import image4 from './slides/SC4.png';
import image5 from './slides/SC5.png';
import image6 from './slides/SC6.png';
import image7 from './slides/SC7.png';
import image8 from './slides/SC8.jpg';
import image9 from './slides/SC9.png';


export default class AboutHydroneer extends React.Component {
    render() {
        const opts = {
            width: "100%",
            playerVars: {
                rel:0,
                controls:0,
                showinfo: 0
            }
        };

        const handleOnDragStart = (e) => e.preventDefault()

        return (
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">What is Hydroneer?</div>
                </div>

                <div style={{marginLeft: "auto", marginRight: "auto", width: "720px", maxWidth: "100%"}}>
                    <YouTube videoId="ZfGr4BZrbJQ" opts={opts} onReady={this._onReady} />
                </div>

                <div className="ui segment">
                    <h2>What is Hydroneer?</h2>
                    <p>Hydroneer is a mining and base building sandbox. Dig for gold and other resources to turn a profit and enhance your mining operation. Build a base of operations, forge weapons, go fishing, and dig deep! It is solely developed by Max Hayon of Foulball Hangover and was released for on May 8, 2020 on Steam.</p>
                    <p>Use primitive tools, hydro-powered machines, and player-built structures to dig and evolve your operation in this tycoon-style progression system.</p>
                    <p>Using a state-of-the-art voxel based terrain system, you can create cave networks, quarry pits, or even mud mountains. Discover relics of the past in the depths of Hydroneer, with better rewards the deeper you dig.</p>
                    <p>Hydroneer features a dynamic modular system for building structures and hydro powered machinery. Create the base of operations from your own design to optimise your work. Create networks of hydro pipes, control pressure. Craft resources, weaponry, and jewellery.</p>
                    <p>Rigs are large hydro powered machines used for a variety of uses, from digging resources to transporting goods.</p>
                    <p>Various parcels of land await you in the world of Hydroneer, each with their own advantages. Deeper pits, attractive scenery, and closer stores. You decide where to build your base of operations.</p>
                </div>

                <iframe src="https://store.steampowered.com/widget/1106840/374005/" title="Buy Hydroneer on Steam" frameBorder="0" width="100%" height="190" />

                <AliceCarousel mouseTrackingEnabled buttonsDisabled responsive={{0: {items: 1,},1024: {items: 3}}}>
                    <img src={image1} onDragStart={handleOnDragStart} className="sliderimg" alt="" />
                    <img src={image2} onDragStart={handleOnDragStart} className="sliderimg" alt="" />
                    <img src={image3} onDragStart={handleOnDragStart} className="sliderimg" alt="" />
                    <img src={image4} onDragStart={handleOnDragStart} className="sliderimg" alt="" />
                    <img src={image5} onDragStart={handleOnDragStart} className="sliderimg" alt="" />
                    <img src={image6} onDragStart={handleOnDragStart} className="sliderimg" alt="" />
                    <img src={image7} onDragStart={handleOnDragStart} className="sliderimg" alt="" />
                    <img src={image8} onDragStart={handleOnDragStart} className="sliderimg" alt="" />
                    <img src={image9} onDragStart={handleOnDragStart} className="sliderimg" alt="" />
                </AliceCarousel>
            </div>
        )
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
}