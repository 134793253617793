import React from 'react';
import {API_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";
import ReactHtmlParser from "react-html-parser";

export default class BlogPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            id: props.match.params.post_id
        }
    }

    async componentDidMount() {
        const url = API_URL + `/api/blogposts/${this.state.id}/`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results[0], loading: false})
    }

    render() {
        return (
            <div className="ui container">
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            <div className="ui breadcrumb inverted">
                                <a href="/" className="section">Bridgepour Wiki Home</a>
                                <i className="right angle icon divider"/>
                                <a href="/blog/" className="section">Blog</a>
                                <i className="right angle icon divider"/>
                                <div className="active section">{this.state.data.title}</div>
                            </div>

                            <br/>
                            <br/>

                            <div className="Page-header" style={{backgroundImage: "url(/assets/images/blog/" + this.state.data.thumbnail + ")"}}>
                                <h1>
                                    {this.state.data.title}
                                    <div className="sub-heading">{ReactHtmlParser (this.state.data.html_summary)}</div>
                                </h1>
                            </div>

                            <div className="ui segment blog-post">
                                <>{ReactHtmlParser (this.state.data.html_body)}</>
                                <div className="ib">
                                    {this.state.data.author}
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        )
    }
}