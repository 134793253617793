import React from "react";
import ReactHtmlParser from "react-html-parser";

const faq_items = [
    ["Is Hydroneer going to have Multiplayer?", "Yes!"],
    ["When is multiplayer coming out?", "Summer 2021"],
    ["How many players will Hydroneer support?", "At the moment it looks like Hydroneer Multiplayer will support 1 to 4 players."],
    ["Can I play my existing saves?", "Unfortunately due to the game being rewritten, existing saves will no longer work. We are planning on having the current version (1.6.x) of Hydroneer available alongside 2.0 so you can still play your older saves."],
    ["Will the world feel more alive?", "Yes! There will be NPC's to interact with, and tasks to fulfil."]
]

export default class MPFAQ extends React.Component {
    render() {
        return (
            <>
                <div className="ui container">

                    <div className="Page-header">
                        <h1>
                            Hydroneer 2.0 (Multiplayer) FAQ
                            <div className="sub-heading">Hydroneer Multiplayer top frequently asked questions.</div>
                        </h1>
                    </div>

                    <div className="ui segment">
                        <div className="ui inverted divided items">
                            {faq_items.map(item => (
                                <>
                                    <div className="item">
                                        <div className="content">
                                            <p className="header">{item[0]}</p>
                                            <div className="description">{ReactHtmlParser(item[1])}</div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}