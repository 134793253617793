import React from 'react';

export default function HydroLogic_a() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book" className="section">Guide Book</a>
                <i className="right angle icon divider"/>
                <div className="active section">HydroLogic</div>
            </div>

            <div className="Page-header">
                <h1>
                    The Ultimate Logic Guide for Hydroneer 1.6
                    <div className="sub-heading">
                        By Touffu64<br/>
                        <small>With the contribution of Dunder, FerretMan and ItchyBeard</small>
                    </div>
                </h1>
            </div>

            <div className="ui message inverted">
                <p>This guide is a work in progress conversion by ItchyBeard, of the PDF written by Touffu64.</p>
            </div>

            <div className="ui segment">
                <p>Logic in Hydroneer can be quite tricky.</p>
                <p>It doesn't always follow the intuitive logic we can encounter every day. It has it's own logic rules, and if you don't know/understand these, you will spend hours trying to understand why your logic system doesn't work, even if you are sure that you clearly built it as it should be (obviously you didn't).</p>
                <p>Before we begin, you have to know that in Hydroneer logic is just signals travelling through cables. And a logic signal is <strong>always</strong> a pulse, not a continuous value sent by a device or a cable. Logic signal = pulse. Sending a signal with a specific value through some specific arrangement of logic cables will allow us to do a lot of cool stuff!</p>
                <p>In this guide, we will explain HOW the logic works in the game. </p>
                <p>The goal is that you can have a complete understanding on how the logic signals behaves, and you will see that many weird behaviors will suddenly become a lot more... logic!</p>
            </div>

            <div className="ui segment">
                <ul>
                    <li><a href="/guide-book/guide/hydro-logic/logic-behaviour/">Logic behaviour</a></li>
                    <li><a href="/guide-book/guide/hydro-logic/logic-elements/">Logic elements</a>
                        <ul>
                            <li><a href="/guide-book/guide/hydro-logic/logic-elements-part-two/">Logic elements (part 2)</a></li>
                            <li><a href="/guide-book/guide/hydro-logic/logic-elements-part-three/">Logic elements (part 3)</a></li>
                        </ul>
                    </li>
                    <li><a href="/guide-book/guide/hydro-logic/build-examples/">Build examples</a></li>
                </ul>
            </div>

            <a className="ui brand button right floated" href="/guide-book/guide/hydro-logic/logic-behaviour/">Logic behaviour <i className="right arrow icon" /></a>
            <p>Next up, learn the mystery of Logic in Hydroneer!</p>
            
            <br/>
        </div>
    )
}