import React from 'react';
import PostSummary from "./PostSummary";
import {API_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";
import {Link} from "react-router-dom";

export default class BlogFeed extends React.Component {
    state = {
        loading: true,
        data: null
    }

    async componentDidMount() {
        const url = API_URL + "/api/blogposts/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})
    }

    render() {
        return (
            <div className="Blog-feed">
                {this.state.loading || !this.state.data ?
                    (
                        <Loader />
                    ) : (
                        <>
                            {this.state.data.slice(0, 5).map(data => (
                                <PostSummary post={data} key={data.title} />
                            ))}
                        </>
                    )
                }

                <Link to="/blog/">View all posts.</Link>
            </div>
        )
    }
}