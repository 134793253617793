import React from 'react';

export default function HydroLogic_e() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book" className="section">Guide Book</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book/guide/hydro-logic" className="section">HydroLogic</a>
                <i className="right angle icon divider"/>
                <div className="active section">II. Logic elements continued again</div>
            </div>

            <div className="Page-header">
                <h1>
                    II. Logic elements continued again
                </h1>
            </div>

            <div className="ui segment">
                <h3>c. Everything else</h3>
                <p>Congratulations, you are now one of a few with a good understanding of Hydroneer's logic!</p>
                <p>In this part we will see all the logic devices that the game has to offer.</p>
                <p>Some are more useful than others, but they all have a purpose.</p>
                <p>First we have the <span className="orange">button</span>. Just press it to send a '1' value.</p>
                <p>Note that it will send the '1', nothing more.</p>
                <p>It will NOT send a '0' value after a short period of time like you could expect.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>A bit similar is the <span className="orange">keypad</span>. That time you get to choose the value
                    you want to send. It can be anything between '0' and '999999999'.</p>
                <p>Press the green button to send the value.</p>
                <p>The red button will reset the value you entered before.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>Next we have the <span className="orange">switch</span>.</p>
                <p>When you move the lever, the <span className="orange">switch</span> will send a signal.</p>
                <p>The value depends on the movement.</p>
                <p>If you bring the lever down, a '1' will be sent.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>If you move the lever up, a '0' will be sent.</p>
                <p>A lot more interesting, is the <span className="orange">durability reader</span>.</p>
                <p>Point that item to something that can be damaged, and it will output the durability value.</p>
                <p>You can leave an empty tile between the reader and the device you want to monitor, it will still
                    work!</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>You can couple it with a <span className="orange">spanner hurling unit</span>.</p>
                <p>That one will throw a spanner at the device touching it's tip.</p>
                <p>That will allow you to have an auto-repair system!</p>
                <p>How awesome is that?</p>
                <p>Of course you will have to charge it with some spanners, it can hold 20 of them.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>Now let's see the glorious <span className="orange">smelter</span>.</p>
                <p>That powerful thing is able to cast ingots (almost) on it's own.</p>
                <p>It needs power to work, and will take damage.</p>
                <p>When ore falls on the pool at the top, it will melt.</p>
                <p>If you send the <span className="orange">smelter</span> a '1' signal, the bottom of the pool will
                    open and it will create an ingot.</p>
                <p>You then need to send it a '0' signal to close the pool and be able to melt ores again.</p>
                <p>Note that the <span className="orange">smelter</span> needs 2 seconds to create an ingot.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>We will finish with the <span className="orange">counter</span>.</p>
                <p>That device will count everything that passes under it.</p>
                <p>It starts at '0', and each time an item is detected, it will increment and send the new value to the
                    end located on it's side.</p>
                <p>The top end is the input and can be used to force the <span className="orange">counter</span> to a
                    specific value. For example, if you want to reset it, just send it a '0' value.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>Oh boy! I almost forgot the <span className="orange">logic valves</span>!</p>
                <p>These are very simple but sooooo convenient.</p>
                <p>They basically open when you send a '1' value, and close when you send a '0' value.</p>
                <p>You have the three kind of logic valves: <span className="orange">water</span>, <span
                    className="orange">power</span> and <span className="orange">logic</span>.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>Okay, now we're good.</p>
                <p>I didn't talk about the <span className="orange">explosives</span> as they don't require any
                    explanation. Just put a <span className="orange">button</span> on it and press it, you'll
                    understand.</p>
            </div>

            <a className="ui brand button" href="/guide-book/guide/hydro-logic/logic-elements-part-two/"><i
                className="left arrow icon"/> Back</a>
            <a className="ui brand button right floated" href="/guide-book/guide/hydro-logic/build-examples/">Build examples<i className="right arrow icon"/></a>
            <br/>
        </div>
    )
}