import React from 'react';

export default function MaxEgg() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <a href="/about" className="section">About Bridgepour Wiki</a>
                <i className="right angle icon divider"/>
                <div className="active section">Max <i className="egg icon" /> </div>
            </div>
            <div className="ui segment">
                <img src="./assets/images/max_shrine.jpg" alt="Shrine to Max." width="100%"/>
            </div>
        </div>
    )
}