import React from 'react';
import GoogleAd from "../Components/GoogleAd";

export default class WorkshopMods extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_mods: false
        }
    }

    componentDidMount() {
        const new_mod_state = localStorage.getItem('show_mods') === 'true';
        this.setState({show_mods: new_mod_state});
    }

    toggleShowMods = () => {
        const new_mod_state = !this.state.show_mods;
        this.setState({
            show_mods: new_mod_state
        })
        localStorage.setItem('show_mods', new_mod_state.toString());
    }

    render() {
        return (
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Mods</div>
                </div>

                <div className="Page-header">
                    <h1>
                        Hydroneer Mods
                        <div className="sub-heading">
                            <p>Check out this page for a link to Steam Workshop supported mods.</p>
                            <p>Not all mods have been ported to the Steam Workshop. To view mods built pre Steam Workshop, go to the <a href="/legacy-mods">Legacy Mods</a> page.</p>
                        </div>
                    </h1>
                </div>

                <div className="ui two column doubling grid container">
                    <div className="eleven wide column">
                        <a href="https://steamcommunity.com/app/1106840/workshop/" target="_blank" rel="noopener noreferrer" className="workshop-banner">&nbsp;</a>

                        <div className="ui segment">
                            <h3>Installing Mods</h3>
                            <p>Steam Workshop integration was built directly in to Hydroneer as of September 11th 2020 (version 1.4)</p>
                            <p>To install mods, go to the <a target="_blank" rel="noopener noreferrer" href="https://steamcommunity.com/app/1106840/workshop/">Steam Workshop</a> page, find the required mod(s) and subscribe to them. The next time you launch Hydroneer, the mod will be available to set as active in from the Mod option of the main menu.</p>
                            <p>After activating a mod, you will need to restart the game for the changes to take affect.</p>
                        </div>

                    </div>
                    <div className="sidebar five wide column">
                        <span className="ui brand button fluid disabled">Steam Workshop Mods</span><br/>
                        <a href="/legacy-mods" className="ui brand button fluid">Legacy Mods</a><br/>
                        <a href="/mod-faq" className="ui brand button fluid">View the Mods FAQ</a>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/kaiheilos" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                Team Hydronix
                            </h3>
                            <div>
                                <p>Team comprised of; Heilos, Rhino, Meaf, NineOClockNate.</p>
                            </div>
                        </div>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/RHlNO/HydroneerModding" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                Rhino
                            </h3>
                        </div>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/rexxar-tc/RexLogic/releases" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                Rexxar
                            </h3>
                        </div>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/Gamerkuipers/Hydroneer-Modding" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                Gamerkuipers
                            </h3>
                        </div>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/ResaloliPT/HydroModTool/releases" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                ResaloliPT
                            </h3>
                        </div>

                        <div className="ui segment">
                            <h3>
                                <a href="https://github.com/VegeoStudios/HydroMods" target="_blank"
                                   rel="noopener noreferrer"
                                   className="ui brand button right floated mini"><i
                                    className="github icon"/> Github</a>
                                Vegeo Studios
                            </h3>
                        </div>

                        <GoogleAd slot="3167129403" />
                        {/*<br/>*/}

                        {/*<div className="ui toggle checkbox inverted branded-slider mini">*/}
                        {/*    <input type="checkbox" id="show_mods" name="show_mods" checked={this.state.show_mods}*/}
                        {/*           onChange={this.toggleShowMods}/>*/}
                        {/*    <label htmlFor="show_mods">Include modded content in the pages around the site.</label>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    }
}