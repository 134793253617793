import React from 'react';
import './ItemCard.sass';
import {API_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";
import {safeName} from "../../Utils/Utils";

export default class ItemCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            item: props.item,
            data: null
        }
    }

    async componentDidMount() {
        const url = API_URL + `/api/items/exact/${this.state.item}/`;
        console.log(url)
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results[0], loading: false})
    }

    addDefaultSrc = (e) => (
        e.target.src = `/assets/images/items/no_image.png`
    )

    render() {
        return (
            <>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            {this.props.no_link ? (
                                <div className="ui fluid card" key={safeName(this.state.data.name)}>
                                    <div className="image">
                                        <img onError={this.addDefaultSrc}
                                             src={"/assets/images/items/" + safeName(this.state.data.name)+ ".png"}
                                             alt={this.state.data.name}
                                        />
                                    </div>
                                    <div className="content">
                                        <div className="header">
                                            {this.state.data.name}
                                        </div>
                                        <div className="meta">
                                            {this.state.data.modded &&
                                            <><i className="wrench icon"/> Modded</>
                                            }
                                        </div>
                                        {this.state.data.description && (
                                            <div className="description">
                                                {this.state.data.description}
                                            </div>
                                        )}
                                    </div>
                                    {(!this.props.hide_details || this.state.data.value) && (
                                        <div className="extra content">
                                            <table className="ui table very basic inverted unstackable">
                                                <tbody>
                                                    {this.state.data.value &&
                                                        <tr>
                                                            <td width="40%">Cost</td>
                                                            <td className="text-right">{(this.state.data.value / 100).toLocaleString(undefined, {maximumFractionDigits: 0})}c</td>
                                                        </tr>
                                                    }
                                                    {!this.props.hide_details &&
                                                        <tr>
                                                            <td colSpan="2">
                                                                <a href={"/database/" + safeName(this.state.data.name)}
                                                                   className="ui brand button mini fluid">
                                                                    Details
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            ):(
                                <a className="ui fluid card" key={safeName(this.state.data.name)} href={"/database/" + safeName(this.state.data.name)}>
                                    <div className="image">
                                        <img onError={this.addDefaultSrc}
                                             src={"/assets/images/items/" + safeName(this.state.data.name)+ ".png"}
                                             alt={this.state.data.name}
                                        />
                                    </div>
                                    <div className="content">
                                        <div className="header">
                                            {this.state.data.name}
                                        </div>
                                        <div className="meta">
                                            {this.state.data.modded &&
                                                <><i className="wrench icon"/> Modded</>
                                            }
                                            {this.props.amt && (
                                                <span className="Item-amount">{this.props.amt}</span>
                                            )}
                                        </div>
                                        {this.state.data.description && (
                                            <div className="description">
                                                {this.state.data.description}
                                            </div>
                                        )}
                                    </div>
                                    {(!this.props.hide_details || this.state.data.value) && (
                                        <div className="extra content">
                                            <table className="ui table very basic inverted unstackable">
                                                <tbody>
                                                    {this.state.data.value &&
                                                        <tr>
                                                            <td width="40%">Cost</td>
                                                            <td className="text-right">{(this.state.data.value / 100).toLocaleString(undefined, {maximumFractionDigits: 0})}c</td>
                                                        </tr>
                                                    }

                                                    {!this.props.hide_details &&
                                                        <tr>
                                                            <td colSpan="2">
                                                                <a href={"/database/" + safeName(this.state.data.name)}
                                                                   className="ui brand button mini fluid">
                                                                    Details
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </a>
                            )}
                        </>
                    )
                }
            </>
        )
    }
}