import React from 'react';
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import {Link} from "react-router-dom";
import {safeName} from "../Utils/Utils";

export default class Database extends React.Component {
    state = {
        loading: true,
        data: null,
        filtered_items: null,
        show_mods: false
    }

    async componentWillMount() {
        const new_mod_state = localStorage.getItem('show_mods') === 'true';
        this.setState({show_mods: new_mod_state})
    }

    async componentDidMount() {
        const url = API_URL + "/api/items/" + (this.state.show_mods ? "all" : "vanilla") + "/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, filtered_items: data.results, loading: false})
    }

    async update_self(e) {
        let mode = this.state.show_mods ? "all" : "vanilla";
        if (e.target.value !== "") {
            mode = "query";
        }
        const url = API_URL + "/api/items/"+mode+"/" + e.target.value.toLowerCase().replace(" ", "-");
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, filtered_items: data.results, loading: false})
    }

    new_filter = (e) => {
        this.setState({loading: true})
        this.update_self(e)
    }

    addDefaultSrc = (e) => {
        e.target.src = `/assets/images/items/thumbs/no_image.png`
    }

    toggleShowMods = () => {
        const new_mod_state = !this.state.show_mods;
        this.setState({
            show_mods: new_mod_state
        })
        localStorage.setItem('show_mods', new_mod_state.toString());
    }

    render() {
        return (
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Item Database</div>
                </div>

                <a href="/list/" className="ui icon button basic mini brand right floated"><i className="list icon" /> Make a list</a>

                <div className="ui segment">
                    <form className="ui form" onSubmit={e => e.preventDefault()}>
                        <div className="field">
                            <label><i className="search icon"/> Find an item</label>
                            <input name="filter" type="text" onChange={this.new_filter}/>
                        </div>
                    </form>

                    <table className="ui table basic inverted striped">
                        <thead>
                        <tr>
                            <th width="32px"><i className="thumbnail icon"/></th>
                            <th>Name</th>
                            <th width="10%">Price</th>
                            <th className="hide-mobile" width="60%">Description</th>
                            <th width="5%">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        <>
                            {this.state.loading || !this.state.filtered_items ?
                                (
                                    <tr>
                                        <td colSpan="5"><Loader/></td>
                                    </tr>
                                ) : (
                                    <>
                                        {this.state.filtered_items.map(item => (
                                            <>
                                                <tr key={item.id}>
                                                    <td>
                                                        <Link to={"/database/" + safeName(item.name)}
                                                              item={safeName(item.name)}>
                                                            <img onError={this.addDefaultSrc}
                                                                 src={"/assets/images/items/thumbs/" + safeName(item.name) + ".png"}
                                                                 alt={item.name}/>
                                                        </Link>
                                                    </td>
                                                    <td><a href={"/database/" + safeName(item.name)}>{item.name}</a>
                                                    </td>
                                                    <td className="Item-value">
                                                        {item.value &&
                                                            (
                                                                <>
                                                                    {(item.value / 100).toLocaleString(undefined, {maximumFractionDigits: 0})}c
                                                                </>
                                                            )
                                                        }

                                                        {!item.value &&
                                                        <span>-</span>
                                                        }
                                                    </td>
                                                    <td className="hide-mobile">
                                                        {item.description &&
                                                        item.description
                                                        }
                                                    </td>
                                                    <td>
                                                        {item.modded &&
                                                            <i className="wrench icon" title="This item is added in via a mod."/>
                                                        }
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                        }
                                    </>
                                )
                            }
                        </>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}