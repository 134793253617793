import React from 'react';
import {API_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";

export default class ItemStores extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            item: props.item,
            data: null
        }
    }

    async componentDidMount() {
        const url = API_URL + `/api/items/exact/${this.state.item}/`;
        console.log(url)
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results[0], loading: false})
    }

    addDefaultSrc = (e) => (
        e.target.src = `/assets/images/items/no_image.png`
    )

    render() {
        return (
            <>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            {this.state.data.stores.length > 0 ? (
                                <div className="ui segment">
                                    <h3>Where to {this.state.data.value ? "buy" : "sell"} {this.state.data.name}</h3>
                                    <p>{this.state.data.name} can be {this.state.data.value ? "bought" : "sold"} in the following
                                        places:</p>
                                    <div className="Town-list">
                                        {this.state.data.stores.map(shop => (
                                            <div key={shop.id}
                                                 className={"ui segment " + shop.town.town_safe_name}>
                                                <h3>{shop.town.name}</h3>
                                                <div className="button-grid">
                                                    <a href={`/guide-book/towns/${shop.town.town_safe_name}/${shop.safe_name}/${shop.id}`}
                                                       className="column four wide ui brand button" key={shop.id}>
                                                        {shop.modded && <i className="wrench icon" />} {shop.name}
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="ui segment">
                                    This item cannot be bought, nor sold.
                                </div>
                            )}
                        </>
                    )
                }
            </>
        )
    }
}