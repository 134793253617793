import React from 'react';
import DigsiteList from "./DigsiteList";

export default function GuidebookDigsites() {
    return (
        <>
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <a href="/guide-book" className="section">Guide Book</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Digsites</div>
                </div>

                <DigsiteList />
            </div>
        </>
    )
}