import React from 'react';
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import ItemCard from "../Components/Items/ItemCard";
import ItemStores from "../Components/Items/ItemStores";
import WeightValueCalculator from "../Utils/WeightValueCalculator";
import CraftingIngredients from "../Components/Items/CraftingIngredients";
import {safeName} from "../Utils/Utils";
import ItemTooltip from "../Utils/ItemTooltip";
import WorldMap from "../Components/Map/Map";
import GoogleAd from "../Components/GoogleAd";
import Helmet from "react-helmet";
import ShareButton from "../Components/ShareAPI";
import ResourceChanceTable from "../Components/Items/ResourceChanceTable";

const grp_diggables = ["Iron Ore", "Gold Ore", "Uncut Emerald", "Uncut Sapphire", "Uncut Ruby", "Shard", "Cloutium Ore", "Dirt"]
const grp_handtools = ["Basic Shovel", "Improved Shovel", "Pickaxe", "Blacksmithing Hammer", "Brush", "Construction Hammer", "Magnet on a Stick.", "Magnet Staff", "Magnifying Glass", "Saw", "Spanner", "Toolbag", "Leg-X"]
const grp_waterpipes = ["Water Elbow Pipe", "Water Pipe Filter", "Water Pipe Valve", "Water Pressure Gauge", "Water T Pipe", "Pressure Tank", "Intake Water Pipe", "Water Pipe"]
const grp_conveyors = ["Conveyor Belt", "Conveyor Centralizer", "Conveyor Shredder", "Left Conveyor Belt", "Right Conveyor Belt", "Conveyor Splitter", "Vertical Conveyor Belt"]
const grp_blueprints = ["Battle Axe Blueprint", "Blunderbuss Blueprint", "Cage Blueprint", "Candle Blueprint", "Elbow Pipe Blueprint", "Pipe Blueprint", "Shield Blueprint", "Spanner Blueprint", "T-Pipe Blueprint", "Teapot Blueprint", "Toolbag Blueprint"]
const grp_blueprints_overflow = ["Elbow Pipe Blueprint", "Pipe Blueprint", "Spanner Blueprint", "T-Pipe Blueprint", "Toolbag Blueprint"]
const grp_logic = ["Add Logic Cable", "Bigger Than Logic Cable", "Compare Logic Cable", "Custom Logic Number", "Delay Logic Cable", "Diode Logic Cable", "Elbow Logic Cable", "Flip Logic Cable", "Logic Button", "Logic Cable Valve", "Logic Counter", "Logic Durability Reader", "Logic Explosives", "Logic Mini Nuke", "Logic Reader Cable", "Logic Smelter", "Logic Spanner Hurling Unit", "Logic Switch", "Logic Valve", "Straight Logic Cable", "Subtract Logic Cable", "T Logic Cable"]
const grp_power = ["Elbow Power Line", "Light Power Line", "Pilot Light Power Line", "Powered Battery", "Powered D.A.V.I.D", "Powered Destroyer", "Powered Filter", "Powered Intake Pipe", "Powered Magnet", "Pressure Booster", "Reactor", "Straight Power Valve", "Straight Power line", "T Power Line"]
const grp_can_have_legs = ["Add Logic Cable", "Bigger Than Logic Cable", "Compare Logic Cable", "Custom Logic Number", "Delay Logic Cable", "Diode Logic Cable", "Elbow Logic Cable", "Flip Logic Cable", "Logic Button", "Logic Cable Valve", "Logic Reader Cable", "Logic Switch", "Straight Logic Cable", "Subtract Logic Cable", "T Logic Cable", "Elbow Power Line", "Light Power Line", "Pilot Light Power Line", "Straight Power line", "T Power Line"]
const grp_filtered_machines = ["Harvester", "Ram Drill", "Goliath Drill"]
const grp_damaged_machines = ["Harvester", "Ram Drill", "Goliath Drill", "Water Pipe Filter", "Powered Filter"]
const grp_pressure_machines = ["Harvester", "Goliath Drill"]
const mag_blueprints = [
    {
        name: "Candle Blueprint",
        output: "candle",
        craft_bar: "gold-bar",
        require_item: "sword",
        require_weight: 12,
        min_craft_weight: 3,
    },
    {
        name: "Teapot Blueprint",
        output: "teapot",
        craft_bar: "iron-bar",
        require_item: "candle",
        require_weight: 12,
        min_craft_weight: 3,
    },
    {
        name: "Elbow Pipe Blueprint",
        output: "water-elbow-pipe",
        craft_bar: "cloutium-bar",
        require_item: "teapot",
        require_weight: 12,
        min_craft_weight: 0.5,
    },
    {
        name: "Shield Blueprint",
        output: "shield",
        craft_bar: "cloutium-bar",
        require_item: "teapot",
        require_weight: 16,
        min_craft_weight: 3,
    },
    {
        name: "T-Pipe Blueprint",
        output: "water-t-pipe",
        craft_bar: "cloutium-bar",
        require_item: "shield",
        require_weight: 16,
        min_craft_weight: 0.5,
    },
    {
        name: "Cage Blueprint",
        output: "cage",
        craft_bar: "cloutium-bar",
        require_item: "shield",
        require_weight: 16,
        min_craft_weight: 3,
    },
    {
        name: "Spanner Blueprint",
        output: "spanner",
        craft_bar: "iron-bar",
        require_item: "cage",
        require_weight: 14,
        min_craft_weight: 4,
    },
    {
        name: "Battle Axe Blueprint",
        output: "battle-axe",
        craft_bar: "cloutium-bar",
        require_item: "cage",
        require_weight: 20,
        min_craft_weight: 3,
    },
    {
        name: "Pipe Blueprint",
        output: "water-pipe",
        craft_bar: "cloutium-bar",
        require_item: "battle-axe",
        require_weight: 12,
        min_craft_weight: 0.5,
    },
    {
        name: "Blunderbuss Blueprint",
        output: "blunderbuss",
        craft_bar: "cloutium-bar",
        require_item: "battle-axe",
        require_weight: 28,
        min_craft_weight: 6,
    },
    {
        name: "Toolbag Blueprint",
        output: "toolbag",
        craft_bar: "iron-bar",
        require_item: "blunderbuss",
        require_weight: 30,
        min_craft_weight: 18,
    },
]
const grp_seedbags = ["Afage Seed Bag", "Ember Spice Seed Bag", "Frost Spine Seed Bag", "Gluuin Seed Bag", "Green Cane Seed Bag", "Hill Lavender Seed Bag", "Lunar Leaf Seed Bag", "Regal Rye Seed Bag", "Streed Thorn Seed Bag", "Wild Bower Seed Bag"]
const grp_machines = ["Ram Drill", "Goliath Drill", "D.A.V.I.D", "Harvester", "Compressor"]
const grp_relics = ["Arrow Relic", "Cross Relic", "Goblet Relic", "Skull Relic", "Spyglass Relic", "Sword Relic"]
const grp_relic_spawns = [
    {
        name: "Arrow Relic",
        digsite: "South Hope"
    }, {
        name: "Cross Relic",
        digsite: "South Hope"
    }, {
        name: "Goblet Relic",
        digsite: "Mildews Aquifer"
    }, {
        name: "Skull Relic",
        digsite: "Dreck Quarry"
    }, {
        name: "Spyglass Relic",
        digsite: "Ember Cradle"
    }, {
        name: "Sword Relic",
        digsite: "Ember Cradle"
    }
]
const grp_seed_spawns = [
    {
        name: "Afage Seed Bag",
        digsite: "South Hope"
    }, {
        name: "Ember Spice Seed Bag",
        digsite: "Dreck Quarry"
    }, {
        name: "Frost Spine Seed Bag",
        digsite: "South Hope"
    }, {
        name: "Gluuin Seed Bag",
        digsite: "Mildews Aquifer"
    }, {
        name: "Green Cane Seed Bag",
        digsite: "Ember Cradle"
    }, {
        name: "Hill Lavender Seed Bag",
        digsite: "Mildews Aquifer"
    }, {
        name: "Lunar Leaf Seed Bag",
        digsite: "South Hope"
    }, {
        name: "Regal Rye Seed Bag",
        digsite: "Dreck Quarry"
    }, {
        name: "Streed Thorn Seed Bag",
        digsite: "Dreck Quarry"
    }, {
        name: "Wild Bower Seed Bag",
        digsite: "Ember Cradle"
    }
]
const grp_fishing_items = ["Fishing Pole.", "Fishing Pot", "Fish", "Boot"]
const grp_crafted_items = ["Dagger", "Axe", "Sword", "Bracelet", "Emerald Ring", "Sapphire Ring", "Ruby Ring", "Emerald Necklace", "Sapphire Necklace", "Ruby Necklace"]
const grp_paint_bucket_items = ["Black Paint Bucket", "Blue Paint Bucket", "Green Paint Bucket", "Orange Paint Bucket", "Pink Paint Bucket", "Red Paint Bucket", "White Paint Bucket", "Paint Brush"]
const grp_paintable_items = ["Large Wall", "Large Wall And Wood Trim", "Large Wall with Window", "Large Wall With Window And Wood Trim", "Large Angled Wall"]
const grp_cart_items = ["Agile Wooden Cart", "Fast Wooden Cart", "Wooden Cart"]

export default class DatabaseView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            item: props.match.params.item,
            data: null
        }
    }

    async componentDidMount() {
        const url = API_URL + `/api/items/exact/${this.state.item}/`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results[0], loading: false})
    }

    addDefaultSrc = (e) => (
        e.target.src = `/assets/images/items/thumbs/no_image.png`
    )

    render() {
        return (
            <div className="ui container">
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            <Helmet>
                                <title>{this.state.data.name} | Bridgepour | Official Hydroneer Wiki</title>
                                <meta name="description" content={this.state.data.description}/>
                            </Helmet>
                            <div className="ui container">
                                <div className="ui breadcrumb inverted">
                                    <a href="/" className="section">Bridgepour Wiki Home</a>
                                    <i className="right angle icon divider"/>
                                    <a href="/database" className="section">Item Database</a>
                                    <i className="right angle icon divider"/>
                                    <div className="active section">{this.state.data.name}</div>
                                </div>

                                {this.state.data.modded && (
                                    <p className="ui message inverted"><i className="info circle icon"/> This item is
                                        added via a mod and is only active when the corresponding mod has been
                                        activated.</p>
                                )}

                                <div className="ui two column grid container mobile reversed stackable">
                                    <div className="eleven wide column">
                                        {this.state.data.name === "World Map" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>Current World Map</h3>
                                                    <p>The World Map displays the map of the game and is the only way to
                                                        view the map in the game.</p>
                                                    <WorldMap/>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Conveyor Splitter" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>

                                                    <p>There are currently 6 resources in Hydroneer that require sorting
                                                        with 5 options on the sorter. You can get around this by
                                                        utilising five sorters to sort out the resources listed here,
                                                        this leaves <ItemTooltip item="dirt"/> as the only thing to come
                                                        out the end of your line of sorters.</p>

                                                    <p>To use the Conveyor Splitter you need to <kbd>Left
                                                        Click</kbd> the dial to make it point to the resource you want
                                                        to come out from <strong>opposite</strong> the dial. Resources
                                                        will pass through from left to right.</p>

                                                    <p>The Conveyor Splitter has five options;</p>
                                                    <div className="ui five cards stackable">
                                                        <ItemCard item="cloutium-ore" hide_details no_link/>
                                                        <ItemCard item="gold-ore" hide_details no_link/>
                                                        <ItemCard item="iron-ore" hide_details no_link/>
                                                        <div className="ui fluid card">
                                                            <div className="image">
                                                                <img src="/assets/images/items/ruby.png" alt="Ruby"/>
                                                            </div>
                                                            <div className="content">
                                                                <div className="header">Gems</div>
                                                                <div className="description">Gems include all variants
                                                                    of Emerald, Sapphire and Ruby.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ItemCard item="shard" hide_details no_link/>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Small Furnace" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>What is the {this.state.data.name}?</h3>
                                                    <p>The Small Furnace is used with a <ItemTooltip
                                                        item="crucible"/> to smelt ores to pour into a <ItemTooltip
                                                        item="casting-mold"/> or to heat up bars ready to be
                                                        smithed on an <ItemTooltip item="anvil"/>.</p>
                                                </div>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>Read on to also find out how to light
                                                        the {this.state.data.name}.</p>
                                                    <p>Pick the Smelter up using the <kbd>E</kbd> key and place it down
                                                        with <kbd>Left Mouse</kbd>. When placed you'll see the fire
                                                        lights up. Once lit, you can place a <ItemTooltip
                                                            item="crucible"/> full of ores to melt ores or to heat up
                                                        bars ready to be smithed. </p>
                                                    <p>Eventually, you can move on to <ItemTooltip item="smelter"/>.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Crucible" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>What is the {this.state.data.name}?</h3>
                                                    <p>The Crucible is used with a <ItemTooltip
                                                        item="small-furnace"/> to smelt ores to pour into a <ItemTooltip
                                                        item="casting-mold"/>.</p>
                                                </div>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>Read on to also find out how to light
                                                        the {this.state.data.name}.</p>
                                                    <p>Drop the Crucible on the floor then drop ores in the top. Once
                                                        you've put as many ores (of the same - see note below!) as you
                                                        like in the Crucible, pick the the Crucible up by
                                                        pressing <kbd>E</kbd> and drop it on the <ItemTooltip
                                                            item="small-furnace"/>.</p>
                                                    <p>You can continue to put as many ores into the Crucible as you
                                                        like before casting the bar. When you're ready to cast a bar,
                                                        pick up the Crucible and use <kbd>Left Mouse</kbd> on
                                                        a <ItemTooltip item="casting-mold"/>.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Smelter" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>What is the {this.state.data.name}?</h3>
                                                    <p>The Smelter combines the functionality <ItemTooltip
                                                        item="small-furnace"/>, <ItemTooltip item="crucible"/> and
                                                        the <ItemTooltip item="casting-mold"/>.</p>
                                                </div>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>Pick the Smelter up using the <kbd>E</kbd> key and place it down
                                                        with <kbd>Left Mouse</kbd>. When placed you'll see the fire
                                                        lights up below. Once lit, you can either manually place or use
                                                        conveyor belts to drop ores into the top of the pot. You'll
                                                        know the ores are melted when you hear the bubbling noise. </p>
                                                    <p>You can continue to put as many ores into the pot as you like
                                                        before casting the bar. When you're ready to cast the bar
                                                        use <kbd>Left Mouse</kbd> on the pot and it'll tip forward and
                                                        cast the bar.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Compressor" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>The Compressor takes any amount of all variants (Uncut, Regular
                                                        and Composite), of all types (Emerald, Sapphire and Ruby) of
                                                        gems. Scroll down to see the variants of all compatible
                                                        gems.</p>
                                                    <p>Either drop them in manually, or use a conveyor system to drop
                                                        them in.</p>
                                                    <p>The Compressor requires water to work. Once there's gems in the
                                                        bucket and water going in, left click on the lever to the right
                                                        side. The lid will close, after it re-opens automatically,
                                                        you'll notice all the gems have compressed into their composite
                                                        variant.</p>
                                                    <p><em>Note: You can leave the Composite Gems in the bucket and keep
                                                        compressing and the weight will accumulate into one Composite
                                                        Gem of each type (Emerald, Sapphire and Ruby).</em></p>
                                                </div>
                                                <div className="ui segment">
                                                    <h3>Emerald</h3>
                                                    <div className="ui link three cards stackable">
                                                        <ItemCard item="uncut-emerald"/>
                                                        <ItemCard item="emerald"/>
                                                        <ItemCard item="composite-emerald"/>
                                                    </div>
                                                </div>
                                                <div className="ui segment">
                                                    <h3>Sapphire</h3>
                                                    <div className="ui link three cards stackable">
                                                        <ItemCard item="uncut-sapphire"/>
                                                        <ItemCard item="sapphire"/>
                                                        <ItemCard item="composite-sapphire"/>
                                                    </div>
                                                </div>
                                                <div className="ui segment">
                                                    <h3>Ruby</h3>
                                                    <div className="ui link three cards stackable">
                                                        <ItemCard item="uncut-ruby"/>
                                                        <ItemCard item="ruby"/>
                                                        <ItemCard item="composite-ruby"/>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {(grp_paint_bucket_items.includes(this.state.data.name) || this.state.data.name === "Paint Brush") && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How do I use the {this.state.data.name}?</h3>
                                                    {this.state.data.name === "Paint Brush" ? (
                                                        <>
                                                            <p>The {this.state.data.name} is used in partner with the
                                                                Various Paint Buckets to paint various wall pieces a
                                                                specific colour.</p>
                                                            <p>Pick up the {this.state.data.name} by
                                                                pressing <kbd>E</kbd>. Look at the paint bucket and
                                                                use <kbd>Left click</kbd> to apply some paint to your
                                                                brush. Now <kbd>Left click</kbd> on the wall you wish to
                                                                paint.</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p>The {this.state.data.name} is used in partner with
                                                                the <ItemTooltip item="paint-brush"/> to paint various
                                                                wall pieces the
                                                                colour {this.state.data.name.replace(" Paint Bucket", "")}.
                                                            </p>
                                                        </>
                                                    )}
                                                </div>

                                                <div className="ui segment">
                                                    <h3>Paintable items</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_paintable_items.map(item => (
                                                            <>
                                                                <a className="item"
                                                                   href={"/database/" + safeName(item)}>
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </a>
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {(["Bed", "Fancy Bed"]).includes(this.state.data.name) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>You need to place down the {this.state.data.name} before you can
                                                        sleep in it. Pick it up using <kbd>E</kbd>, then <kbd>Left
                                                            click</kbd> to enter place mode. Use <kbd>Left
                                                            click</kbd> when you've found a place to set
                                                        your {this.state.data.name} down. When placed, you can <kbd>Left
                                                            click</kbd> to interact with it and skip forward in time to
                                                        the next morning.</p>
                                                </div>
                                            </>
                                        )}

                                        {(["Logic Explosives", "Logic Mini Nuke"]).includes(this.state.data.name) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>You need to place down the {this.state.data.name}. Pick it up
                                                        using <kbd>E</kbd>, then <kbd>Left click</kbd> to enter place
                                                        mode. Use <kbd>Left click</kbd> when you've found a place to set
                                                        your {this.state.data.name} down.</p>
                                                    <p>Once placed, you can hook up a Logic circuit. The easiest way to
                                                        detonate the {this.state.data.name} is by using a <ItemTooltip
                                                            item="logic-button"/> or a <ItemTooltip
                                                            item="logic-switch"/> as the input.</p>

                                                    <h3>How big is the blast radius?</h3>

                                                    {this.state.data.name === "Logic Explosives" && (
                                                        <>
                                                            <p>The {this.state.data.name} will produce a hole with a
                                                                radius of 2.5 blocks in all directions.</p>
                                                            <p>All items, except Vehicles, within a 5 block radius in
                                                                all directions will be destroyed.</p>
                                                        </>
                                                    )}
                                                    {this.state.data.name === "Logic Mini Nuke" && (
                                                        <>
                                                            <p>The {this.state.data.name} will produce a hole with a
                                                                radius of 11.5 blocks in all directions.</p>
                                                            <p>All items, except Vehicles, within a 9 block radius in
                                                                all directions will be destroyed.</p>
                                                        </>
                                                    )}

                                                </div>
                                            </>
                                        )}

                                        {this.state.data.resource && (
                                            <>
                                                {!(["Scrap Bar", "Scrap Metal", "Dirt"]).includes(this.state.data.name) && (
                                                    <WeightValueCalculator item={this.state.item}/>
                                                )}
                                                <CraftingIngredients item={this.state.item}/>
                                            </>
                                        )}

                                        {this.state.data.name === "Bookcase" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>The Bookcase is used to store Blueprints which are listed
                                                        below.</p>
                                                    <p>You can store up to 8 blueprints on a Bookcase. This means to
                                                        store all blueprints you will need to
                                                        buy <strong>two</strong> Bookcases.</p>
                                                    <p>To put a blueprint on the shelf, place down the Bookcase and pick
                                                        up your blueprint using <kbd>E</kbd>, then <kbd>Left
                                                            click</kbd> the Bookcase.</p>
                                                    <p>To take a blueprint off the shelf, point your cursor over the
                                                        blueprint you wish to pick up, then press <kbd>E</kbd>.</p>
                                                </div>

                                                <div className="ui segment">
                                                    <h3>Blueprints</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_blueprints.map(item => (
                                                            <>
                                                                <a className="item"
                                                                   href={"/database/" + safeName(item)}>
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </a>
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {grp_fishing_items.includes(this.state.data.name) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>What is fishing?</h3>
                                                    <p>Fishing is a side activity in Hydroneer and requires one of two
                                                        tools, a <ItemTooltip item="fishing-pole."/> or a <ItemTooltip
                                                            item="fishing-pot"/>.</p>
                                                    <p>You can catch various size (Red, Yellow, Blue or
                                                        Green) <ItemTooltip item="fish"/> or a stinky old <ItemTooltip
                                                            item="boot"/>. These items can vary in weight, thus making
                                                        some catches more valuable than others.</p>
                                                </div>

                                                {this.state.data.name === "Boot" && (
                                                    <>
                                                        <div className="ui segment">
                                                            <h3>What do I obtain a {this.state.data.name}?</h3>
                                                            <p>There are two ways in Hydroneer to obtain
                                                                a {this.state.data.name}. The first way to obtain
                                                                a {this.state.data.name} is via fishing, they have a
                                                                small chance to be caught. The other way is to drop a
                                                                stack of coins into any well. Wells can be found around
                                                                the world of Hydroneer in the various towns. Once you
                                                                drop money in, a Boot will spawn at the exact coin value
                                                                that you dropped in to the well. To get your money back,
                                                                simply sell the boot at any jeweler.</p>
                                                        </div>
                                                    </>
                                                )}

                                                {this.state.data.name === "Fishing Pole." && (
                                                    <div className="ui segment">
                                                        <h3>How do I fish using the {this.state.data.name}?</h3>
                                                        <p>Pick up the {this.state.data.name} using <kbd>E</kbd>. Stand
                                                            next to any body of water, look out at the water and <kbd>Left
                                                                click</kbd> to cast out your line. Once the bobber has
                                                            bobbed three times <kbd>Left click</kbd> again to pull your
                                                            catch in.</p>

                                                    </div>
                                                )}

                                                {this.state.data.name === "Fishing Pot" && (
                                                    <div className="ui segment">
                                                        <h3>How do I fish using the {this.state.data.name}?</h3>
                                                        <p>Pick up the {this.state.data.name} using <kbd>E</kbd>. Stand
                                                            next to any body of water, enter place mode for
                                                            the {this.state.data.name} using <kbd>Left click</kbd>,
                                                            place the {this.state.data.name} under the water. After a
                                                            minute or two, you'll see a <ItemTooltip
                                                                item="fish"/> inside! Pick up
                                                            the {this.state.data.name} using <kbd>E</kbd>, your catch
                                                            will be waiting for you to pick it up.</p>
                                                    </div>
                                                )}
                                            </>
                                        )}

                                        {grp_blueprints.includes(this.state.data.name) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>About Blueprints</h3>
                                                    <p>Blueprints can be exchanged on the lower deck of The Barge just
                                                        outside of Bridgepour.</p>
                                                    <p>You can store up to 8 blueprints on a <ItemTooltip
                                                        item="bookcase"/>. This means to store all blueprints you will
                                                        need to buy <strong>two</strong> Bookcases.</p>
                                                </div>

                                                {mag_blueprints.filter(x => x.name === this.state.data.name).map(bp => (
                                                    <>
                                                        <div className="ui segment">
                                                            <h3>Research requirement</h3>
                                                            <p>To unlock this blueprint for use, you are required to
                                                                craft a <ItemTooltip item={bp.require_item}/> (with a
                                                                minimum weight of {bp.require_weight}) like you would
                                                                normally, then bring it with you to The Barge and place
                                                                it in/on the bucket to the left of the table. Then place
                                                                the {this.state.data.name} on the table, then hit the
                                                                button to unlock the blueprint.</p>
                                                            <p className="ui message inverted">
                                                                <em>
                                                                    <strong>
                                                                        Note: excess item weight will be lost.
                                                                    </strong>
                                                                </em>
                                                            </p>
                                                            <p>You can now take this blueprint back to your <ItemTooltip
                                                                item="workbench"/> and begin construction!</p>
                                                        </div>

                                                        <div className="ui segment">
                                                            <h3>Construction requirements</h3>
                                                            {grp_blueprints_overflow.includes(this.state.data.name) && (
                                                                <p className="ui message inverted">
                                                                    <em>
                                                                        <strong>
                                                                            Note: excess item weight will be lost.
                                                                        </strong>
                                                                    </em>
                                                                </p>
                                                            )}
                                                            <p>To craft
                                                                the {this.state.data.name.replace(" Blueprint", "")} you
                                                                need to place this blueprint on the left side of
                                                                the <ItemTooltip item="workbench"/> and
                                                                a <strong>hot</strong> <ItemTooltip
                                                                    item={bp.craft_bar}/> (with a minimum weight
                                                                of {bp.min_craft_weight}) on the right side of the
                                                                bench. Then use the <ItemTooltip item="screwdriver"/> on
                                                                the bench and
                                                                the {this.state.data.name.replace(" Blueprint", "")} will
                                                                appear.</p>
                                                        </div>

                                                        <div className="ui segment">
                                                            <h3>Output Item</h3>
                                                            <p>When constructed on the workbench, you will receive this
                                                                item at the weight of the resource used to construct
                                                                this item.</p>
                                                            <div className="ui link three cards stackable">
                                                                <ItemCard item={bp.output}/>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}

                                                <div className="ui segment">
                                                    <h3>Blueprints</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_blueprints.map(item => (
                                                            <>
                                                                {item === this.state.data.name && (
                                                                    <div className="item active">
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item !== this.state.data.name && (
                                                                    <a className="item"
                                                                       href={"/database/" + safeName(item)}>
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </a>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Anvil" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>The Anvil should be placed on a foundation. Interacting with the
                                                        Anvil using <kbd>Left click</kbd> will allow you to select a
                                                        recipe. There are 6 recipes to cycle through on
                                                        the {this.state.data.name}. Once a recipe is selected, you'll
                                                        see the bar requirements next to the icon on the top face of the
                                                        anvil. Make sure to heat up your bars on a <ItemTooltip
                                                            item="small-furnace"/> so they are red hot. When the
                                                        required resources are prepared for crafting, pick up
                                                        your <ItemTooltip
                                                            item="blacksmithing-hammer"/> using <kbd>E</kbd> then <kbd>Left
                                                            click</kbd> the Anvil (not the resource!) to craft the item.
                                                    </p>

                                                    <div className="ui three cards stackable">
                                                        <div className="ui fluid card">
                                                            <div className="image">
                                                                <img src="/assets/images/items/dagger.png"
                                                                     alt="Dagger"/>
                                                            </div>
                                                            <div className="content">
                                                                <div className="header">Dagger</div>
                                                                <div className="description">The Dagger requires 1x Hot
                                                                    Iron Bar
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ui fluid card">
                                                            <div className="image">
                                                                <img src="/assets/images/items/axe.png" alt="Axe"/>
                                                            </div>
                                                            <div className="content">
                                                                <div className="header">Axe</div>
                                                                <div className="description">The Axe requires 2x Hot
                                                                    Iron Bars
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ui fluid card">
                                                            <div className="image">
                                                                <img src="/assets/images/items/sword.png" alt="Sword"/>
                                                            </div>
                                                            <div className="content">
                                                                <div className="header">Sword</div>
                                                                <div className="description">The Sword requires 3x Hot
                                                                    Iron Bars
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ui fluid card">
                                                            <div className="image">
                                                                <img src="/assets/images/items/bracelet.png"
                                                                     alt="Bracelet"/>
                                                            </div>
                                                            <div className="content">
                                                                <div className="header">Bracelet</div>
                                                                <div className="description">The Bracelet requires 1x
                                                                    Hot Gold Bar
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ui fluid card">
                                                            <div className="image">
                                                                <img src="/assets/images/items/ruby-ring.png"
                                                                     alt="Ring"/>
                                                            </div>
                                                            <div className="content">
                                                                <div className="header">Ring</div>
                                                                <div className="description">The Bracelet requires 1x
                                                                    Hot Gold Bar and any cut or compressed gem.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="ui fluid card">
                                                            <div className="image">
                                                                <img src="/assets/images/items/emerald-necklace.png"
                                                                     alt="Necklace"/>
                                                            </div>
                                                            <div className="content">
                                                                <div className="header">Necklace</div>
                                                                <div className="description">The Necklace requires 2x
                                                                    Hot Gold Bar and any cut or compressed gem.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="ui segment">
                                                    <h3>Help, I can't make items!</h3>
                                                    <p>Have you selected a recipe?</p>
                                                    <p>Are you using a <ItemTooltip item="blacksmithing-hammer"/> rather
                                                        than a <ItemTooltip item="construction-hammer"/>?</p>
                                                    <p>Have you heated up your bars, using a <ItemTooltip
                                                        item="small-furnace"/>?</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Ram Drill" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>The Ram Drill needs to be placed on diggable dirt and will ram
                                                        out chunks of <ItemTooltip item="dirt"/> every few seconds, the
                                                        dirt underneath the drill will never need to be replaced.</p>
                                                    <p>You can decrease the time between ramming by using a <ItemTooltip
                                                        item="pressure-tank"/> to increase the pressure.</p>
                                                    <p>The Ram Drill will also take damage over time, you can increase
                                                        the time before the drill gets damaged by using a <ItemTooltip
                                                            item="water-pipe-filter"/>.</p>
                                                    <p><strong>NOTE</strong>: The Ram Drill will only ever produce top
                                                        level dirt, no matter how high or low you place it.</p>
                                                </div>
                                                <div className="ui segment">
                                                    <h3>Help, it's not drilling!</h3>
                                                    <p>As mentioned above the Ram Drill needs to be placed on diggable
                                                        dirt. The Ram Drill will also not function if there is dirt too
                                                        high up inside the machine.</p>
                                                    <p>If you're having trouble getting the dirt in the right place, you
                                                        can put down a <ItemTooltip item="window"/> or a <ItemTooltip
                                                            item="wood-foundation"/> - using a shovel, you can scoop a
                                                        dirt pile on the center at the top of the block you placed, now
                                                        place the Ram Drill on the top of the block and you should be
                                                        alright.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Goliath Drill" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>The Goliath Drill will dig up diggable dirt using its' exending
                                                        drill arm from below. The dirt will need to either be manually
                                                        replenished using a <ItemTooltip
                                                            item="basic-shovel"/> or <ItemTooltip
                                                            item="improved-shovel"/>. Eventually the dirt can be
                                                        automatically replenished by utilizing the <ItemTooltip
                                                            item="david"/>.</p>
                                                    <p>The Goliath Drill will spit out bigger chunks of <ItemTooltip
                                                        item="dirt"/> than the <ItemTooltip item="ram-drill"/>. The size
                                                        and value of the ore will be more lucrative by digging dirt
                                                        further below the surface. The most lucrative resources are dug
                                                        from the bottom of the plots.</p>
                                                    <p>Processing dirt can be done manually by using a <ItemTooltip
                                                        item="pan"/>, or automatically by using a <ItemTooltip
                                                        item="harvester"/>.</p>
                                                </div>
                                                <div className="ui segment">
                                                    <h3>Help, it's not drilling!</h3>
                                                    <p>As mentioned above the Goliath Drill needs to have diggable dirt
                                                        below and within range of the extending drill arm. The Goliath
                                                        will also not function if there is dirt too high up inside the
                                                        machine.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "D.A.V.I.D" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>The D.A.V.I.D, when powered with Water and Shards, will fire
                                                        unlimited blobs of dirt in the direction it is facing. The blob
                                                        of dirt will travel until that blob of dirt hits another
                                                        block/machine. The D.A.V.I.D is best paired with a <ItemTooltip
                                                            item="goliath-drill"/> for maximum efficiency.</p>
                                                    <p>Dirt value is always determined on where it is dug (using a
                                                        shovel or the Goliath drill tip) <strong>from</strong> not where
                                                        it is fired from.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Harvester" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>The Harvester takes chunks of <ItemTooltip item="dirt"/> in
                                                        through the grinders at the top, and spits out resources from
                                                        the chute at the front.</p>
                                                    <p>You can use a <ItemTooltip item="pressure-tank"/> to increase the
                                                        pressure which increases resource yield.</p>
                                                    <p>The Harvester will also take damage over time, you can increase
                                                        the time before the drill gets damaged by using a <ItemTooltip
                                                            item="water-pipe-filter"/>.</p>
                                                </div>
                                            </>
                                        )}

                                        {(["Basic Shovel", "Improved Shovel"]).includes(this.state.data.name) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>To use the {this.state.data.name} pick it up using <kbd>E</kbd>.
                                                        Whilst holding the {this.state.data.name}, <kbd>Left
                                                            click</kbd> on <strong>diggable</strong> dirt to get a scoop
                                                        of dirt. {this.state.data.name} will
                                                        scoop {this.state.data.name === "Basic Shovel" ? "1 chunk" : "2 chunks"}
                                                        &nbsp;of dirt into a <ItemTooltip item="bucket"/> or
                                                        a <ItemTooltip item="pan"/>.
                                                    </p>
                                                    <p>You can also place dirt back down on the ground to create dirt
                                                        structures or fill holes back in.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Pickaxe" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>To use the {this.state.data.name} pick it up using <kbd>E</kbd>.
                                                        Whilst holding the {this.state.data.name}, <kbd>Left
                                                            click</kbd> on <strong>diggable</strong> dirt to remove a
                                                        sphere of dirt (this dirt will be
                                                        deleted, <strong>forever</strong>).
                                                    </p>
                                                    <p>You can also use the {this.state.data.name} to evenly split
                                                        chunks of dirt and stacks of money.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Saw" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>To use the {this.state.data.name} pick it up using <kbd>E</kbd>.
                                                        Whilst holding the {this.state.data.name}, <kbd>Left click</kbd> on a bar or a stack of coins to evenly split them.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Blacksmithing Hammer" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>Ensure you have selected a recipe on the <ItemTooltip
                                                        item="anvil"/> (more info on the Anvil page), and have the
                                                        sufficient resources required (heated bars too!).</p>
                                                    <p>To use the {this.state.data.name} pick it up using <kbd>E</kbd>.
                                                        Whilst holding the {this.state.data.name}, <kbd>Left
                                                            click</kbd> on the Anvil and the resources will be consumed
                                                        and the crafted item will appear.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Brush" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>To use the {this.state.data.name} you need to have dirt and water
                                                        in a <ItemTooltip item="pan"/>. Once that's done, pick up
                                                        the {this.state.item.name} using <kbd>E</kbd> then <kbd>Left
                                                            click</kbd> on the Pan twice, the dirt will be cleaned and
                                                        resources will appear inside the pan.</p>
                                                </div>
                                            </>
                                        )}

                                        {(["Spanner", "Toolbag"]).includes(this.state.data.name) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>To use the {this.state.data.name} pick it up using <kbd>E</kbd> then <kbd>Left Click</kbd> a damaged machine.</p>
                                                    <p>The {this.state.data.name} has <strong>{this.state.data.name === "Spanner" ? "ONE":"FIVE"}</strong> use(s).</p>
                                                    <p>To check the status of machines, you can use a <ItemTooltip item="magnifying-glass" />.</p>
                                                    {this.state.data.name === "Spanner" && (
                                                        <p>You can save a bit of money by buying a <ItemTooltip item="toolbag"/> instead, the Toolbag has 5 uses.</p>
                                                    )}
                                                </div>

                                                <div className="ui segment">
                                                    <h3>Which machines take damage?</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_damaged_machines.map(item => (
                                                            <>
                                                                <a className="item"
                                                                   href={"/database/" + safeName(item)}>
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </a>
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Magnifying Glass" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>To use the {this.state.data.name} pick it up using <kbd>E</kbd> then simply look at a device which takes damage.
                                                        To repair damaged machines, you can use a <ItemTooltip item="spanner" /> or a <ItemTooltip item="toolbag" />.
                                                    </p>
                                                </div>

                                                <div className="ui segment">
                                                    <h3>Which machines take damage?</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_damaged_machines.map(item => (
                                                            <>
                                                                <a className="item"
                                                                   href={"/database/" + safeName(item)}>
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </a>
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {(["Magnet on a Stick.", "Magnet Staff"]).includes(this.state.data.name) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>To use the {this.state.data.name} pick it up using <kbd>E</kbd>.
                                                        Whilst holding the {this.state.data.name}, look at your resources and
                                                        hold <kbd>Left click</kbd>. Resources will move towards the end of
                                                        the {this.state.data.name} and form a ball on the end.
                                                    </p>
                                                    <p>Release<kbd>Left click</kbd> to drop the resources.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Leg-X" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>To use the {this.state.data.name} pick up
                                                        the {this.state.item.name} using <kbd>E</kbd> then <kbd>Left
                                                            click</kbd> on a placed Power or Logic cable to add decorative legs.</p>
                                                    <p>Decorative legs cannot be added to water pipes.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Construction Hammer" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>To use the {this.state.data.name} pick up
                                                        the {this.state.item.name} using <kbd>E</kbd> then <kbd>Left
                                                            click</kbd> on a placed object to lock it down. To unlock an
                                                        item, simply click it again. If the object has an orange
                                                        outline, it isn't locked down.</p>
                                                    <p>To lock a <ItemTooltip item="tool-rack"/> down, first you need to
                                                        fill it with tools, then hit it with the {this.state.data.name}.
                                                    </p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Water Pipe Filter" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>What is the {this.state.data.name}?</h3>
                                                    <p>The {this.state.data.name} will filter water by 20%, so it's good
                                                        to place <strong>5 of them in a row</strong>. Each machine
                                                        doesn't need their own filter so you can place a network
                                                        of {this.state.data.name} for multiple machines.</p>
                                                </div>

                                                <div className="ui segment">
                                                    <h3>Which machines require filtered water?</h3>
                                                    <p>Many machines will function more efficiently with fully filtered
                                                        water, the following machines require filtered water to function
                                                        at their best.</p>
                                                    <div className="ui horizontal link list">
                                                        {grp_filtered_machines.map(item => (
                                                            <>
                                                                <a className="item"
                                                                   href={"/database/" + safeName(item)}>
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </a>
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Water Pressure Gauge" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>What does the {this.state.data.name} do?</h3>
                                                    <p>The {this.state.data.name} can be placed anywhere in your water
                                                        pipe network and will display the <strong>water
                                                            pressure</strong> at that exact point in the network. The
                                                        scale is 0 to 1, where 1 is 100% (maximum) pressure.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Intake Water Pipe" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>What does the {this.state.data.name} do?</h3>
                                                    <p>The {this.state.data.name} should be placed in any body of water,
                                                        once placed you will notice it's working. Drip. <em>DrIp..</em>
                                                        <strong>DRIP!?!!</strong></p>
                                                    <p>The {this.state.data.name} will provide a base of 50% water
                                                        pressure in to your pipe network, pressure can be increased by
                                                        25% using a <ItemTooltip item="pressure-tank"/>. Water Pressure
                                                        caps at 100%, going "higher" than this has no effect.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Water Pipe Valve" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>What does the {this.state.data.name} do?</h3>
                                                    <p>The {this.state.data.name} can be placed anywhere in your water
                                                        pipe network and, when turned, will stop or allow to flowing of
                                                        water to the rest of the pipe network.</p>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Pressure Tank" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>What is the {this.state.data.name}?</h3>
                                                    <p>The {this.state.data.name} will increase the pressure in your
                                                        pipe network by 25%. Machines function their best when supplied
                                                        maximum pressure (100%) and maximum filtered water (using
                                                        multiple <ItemTooltip item="water-pipe-filter"/>).</p>
                                                    <p>To get the most pressure to your machines, it is advised you
                                                        place {this.state.data.name} as close to your machines as
                                                        possible, and as many needed to boost the pressure to the
                                                        maximum (100%).</p>
                                                </div>

                                                <div className="ui segment">
                                                    <h3>Which machines benefit from higher pressure water?</h3>
                                                    <p>Many machines will function more efficiently with maximum
                                                        pressure water, the following machines will function at their
                                                        best if supplied maximum pressure.</p>
                                                    <div className="ui horizontal link list">
                                                        {grp_pressure_machines.map(item => (
                                                            <>
                                                                <a className="item"
                                                                   href={"/database/" + safeName(item)}>
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </a>
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {this.state.data.name === "Workbench" && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>Blueprints can be placed upon the left side of the <ItemTooltip
                                                        item="workbench"/> along with the required bar
                                                        (<strong>heated</strong>) on the right side of the bench, then
                                                        use a <ItemTooltip item="screwdriver"/> on the workbench to
                                                        construct the item.</p>
                                                </div>

                                                <div className="ui segment">
                                                    <h3>Blueprint Unlock Tree</h3>
                                                    <p className="ui message inverted">This section is a work in
                                                        progress and will be made tidier over time.</p>
                                                    <ul>
                                                        <li>Candle Blueprint: Requires Sword (12 weight)
                                                            <ul>
                                                                <li>Teapot Blueprint: Requires Candle (12 weight)
                                                                    <ul>
                                                                        <li>Elbow Pipe Blueprint: Requires Teapot
                                                                            (12 weight)
                                                                        </li>
                                                                        <li>Shield Blueprint: Requires Teapot (16
                                                                            weight)
                                                                            <ul>
                                                                                <li>Water T Pipe Blueprint: Requires
                                                                                    Shield (16 weight)
                                                                                </li>
                                                                                <li>Cage Blueprint: Requires Shield (16
                                                                                    weight)
                                                                                    <ul>
                                                                                        <li>Spanner Blueprint: Requires
                                                                                            Cage (14 weight)
                                                                                        </li>
                                                                                        <li>Battle Axe Blueprint:
                                                                                            Requires Cage (20 weight)
                                                                                            <ul>
                                                                                                <li>Straight Pipe
                                                                                                    Blueprint: Requires
                                                                                                    Battle Axe (12
                                                                                                    weight)
                                                                                                </li>
                                                                                                <li>Blunderbuss
                                                                                                    Blueprint: Requires
                                                                                                    Battle Axe (28
                                                                                                    weight)
                                                                                                    <ul>
                                                                                                        <li>Toolbag
                                                                                                            Blueprint:
                                                                                                            Requires
                                                                                                            Blunderbuss
                                                                                                            (30 weight)
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </li>
                                                                                    </ul>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="ui segment">
                                                    <h3>Blueprints</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_blueprints.map(item => (
                                                            <>
                                                                {item === this.state.data.name && (
                                                                    <div className="item active">
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item !== this.state.data.name && (
                                                                    <a className="item"
                                                                       href={"/database/" + safeName(item)}>
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </a>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {!this.state.data.blueprint && (
                                            <ItemStores item={this.state.item}/>
                                        )}

                                        {grp_relics.includes(this.state.data.name) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>What are relics?</h3>
                                                    <p>Relics can be uncovered whilst digging dirt in the various plots
                                                        of land.</p>
                                                    <p>Don't worry, no spoilers for locations, if you want find out
                                                        where to uncover each relic, expand the boxes below.</p>
                                                    <p>Learn more about <a href="/guide-book/digsites">digsites</a>.</p>
                                                </div>

                                                {grp_relic_spawns.filter(x => x.name === this.state.data.name).map(seed => (
                                                    <>
                                                        <details className="ui segment">
                                                            <summary>Spawn Locations</summary>
                                                            <div>
                                                                <h3>{seed.digsite}</h3>
                                                                <img alt={this.state.data.name}
                                                                     src={"/assets/images/relics-" + safeName(seed.digsite) + ".jpg"}
                                                                     width="100%"/>
                                                            </div>
                                                        </details>
                                                        <details className="ui segment">
                                                            <summary>Other relics at this digsite.</summary>

                                                            <div className="ui horizontal link list">
                                                                {grp_relic_spawns.filter(x => x.digsite === seed.digsite).map(x => (
                                                                    <>
                                                                        {x.name === this.state.data.name && (
                                                                            <div className="item active">
                                                                                <img className="ui avatar image"
                                                                                     onError={this.addDefaultSrc}
                                                                                     src={"/assets/images/items/thumbs/" + safeName(x.name) + ".png"}
                                                                                     alt={x.name}/>
                                                                                <div className="content">
                                                                                    <div
                                                                                        className="header">{x.name}</div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {x.name !== this.state.data.name && (
                                                                            <a className="item"
                                                                               href={"/database/" + safeName(x.name)}>
                                                                                <img className="ui avatar image"
                                                                                     onError={this.addDefaultSrc}
                                                                                     src={"/assets/images/items/thumbs/" + safeName(x.name) + ".png"}
                                                                                     alt={x.name}/>
                                                                                <div className="content">
                                                                                    <div
                                                                                        className="header">{x.name}</div>
                                                                                </div>
                                                                            </a>
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </details>
                                                    </>
                                                ))}

                                                <div className="ui segment">
                                                    <h3>How to use the {this.state.data.name}?</h3>
                                                    <p>Relics can be placed in the <span className="redacted">secret cave</span> which
                                                        can be found near <span className="redacted">Burnscroft</span>,
                                                        close to <span className="redacted">Tools & Equipment</span>
                                                    </p>
                                                </div>

                                                <div className="ui segment">
                                                    <h3>Other relics</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_relics.map(item => (
                                                            <>
                                                                {item === this.state.data.name && (
                                                                    <div className="item active">
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item !== this.state.data.name && (
                                                                    <a className="item"
                                                                       href={"/database/" + safeName(item)}>
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </a>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {grp_can_have_legs.includes(this.state.data.name) && (
                                            <div className="ui message inverted">
                                                Legs can be added to this item using the <ItemTooltip
                                                item="leg-x"/> tool!
                                            </div>
                                        )}

                                        {grp_logic.includes(this.state.data.name) && (
                                            <div className="ui segment">
                                                <h3>Other logic items</h3>
                                                <div className="ui horizontal link list">
                                                    {grp_logic.map(item => (
                                                        <>
                                                            {item === this.state.data.name && (
                                                                <div className="item active">
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item !== this.state.data.name && (
                                                                <a className="item"
                                                                   href={"/database/" + safeName(item)}>
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </a>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {grp_power.includes(this.state.data.name) && (
                                            <div className="ui segment">
                                                <h3>Other power items</h3>
                                                <div className="ui horizontal link list">
                                                    {grp_power.map(item => (
                                                        <>
                                                            {item === this.state.data.name && (
                                                                <div className="item active">
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item !== this.state.data.name && (
                                                                <a className="item"
                                                                   href={"/database/" + safeName(item)}>
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </a>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {grp_handtools.includes(this.state.data.name) && (
                                            <div className="ui segment">
                                                <h3>Other hand tools</h3>
                                                <div className="ui horizontal link list">
                                                    {grp_handtools.map(item => (
                                                        <>
                                                            {item === this.state.data.name && (
                                                                <div className="item active">
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item !== this.state.data.name && (
                                                                <a className="item"
                                                                   href={"/database/" + safeName(item)}>
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </a>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {grp_cart_items.includes(this.state.data.name) && (
                                            <div className="ui segment">
                                                <h3>Other carts</h3>
                                                <div className="ui horizontal link list">
                                                    {grp_cart_items.map(item => (
                                                        <>
                                                            {item === this.state.data.name && (
                                                                <div className="item active">
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item !== this.state.data.name && (
                                                                <a className="item"
                                                                   href={"/database/" + safeName(item)}>
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </a>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {grp_waterpipes.includes(this.state.data.name) && (
                                            <div className="ui segment">
                                                <h3>Other water pipes</h3>
                                                <div className="ui horizontal link list">
                                                    {grp_waterpipes.map(item => (
                                                        <>
                                                            {item === this.state.data.name && (
                                                                <div className="item active">
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item !== this.state.data.name && (
                                                                <a className="item"
                                                                   href={"/database/" + safeName(item)}>
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </a>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {grp_conveyors.includes(this.state.data.name) && (
                                            <div className="ui segment">
                                                <h3>Other conveyor belts</h3>
                                                <div className="ui horizontal link list">
                                                    {grp_conveyors.map(item => (
                                                        <>
                                                            {item === this.state.data.name && (
                                                                <div className="item active">
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {item !== this.state.data.name && (
                                                                <a className="item"
                                                                   href={"/database/" + safeName(item)}>
                                                                    <img className="ui avatar image"
                                                                         onError={this.addDefaultSrc}
                                                                         src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                         alt={item}/>
                                                                    <div className="content">
                                                                        <div className="header">{item}</div>
                                                                    </div>
                                                                </a>
                                                            )}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {grp_seedbags.includes(this.state.data.name) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>Obtaining {this.state.data.name.replace("Seed Bag", "Seeds")}</h3>
                                                    <p>
                                                        Seed Bags can be found in various places around the world of
                                                        Hydroneer.
                                                    </p>

                                                    {grp_seed_spawns.filter(x => x.name === this.state.data.name).map(seed => (
                                                        <>
                                                            <p>This seed bag can spawn in one of many points in
                                                                the <span
                                                                    className="redacted">{seed.digsite}</span> digsite.
                                                            </p>
                                                        </>
                                                    ))}
                                                </div>

                                                {grp_seed_spawns.filter(x => x.name === this.state.data.name).map(seed => (
                                                    <>
                                                        <details className="ui segment">
                                                            <summary>Spawn Locations</summary>
                                                            <div>
                                                                <h3>{seed.digsite}</h3>
                                                                <img alt={this.state.data.name}
                                                                     src={"/assets/images/items/plants/" + safeName(seed.digsite) + "-plants.jpg"}
                                                                     width="100%"/>
                                                            </div>
                                                        </details>
                                                        <details className="ui segment">
                                                            <summary>Other seeds at this digsite.</summary>

                                                            <div className="ui horizontal link list">
                                                                {grp_seed_spawns.filter(x => x.digsite === seed.digsite).map(x => (
                                                                    <>
                                                                        {x.name === this.state.data.name && (
                                                                            <div className="item active">
                                                                                <img className="ui avatar image"
                                                                                     onError={this.addDefaultSrc}
                                                                                     src={"/assets/images/items/thumbs/" + safeName(x.name) + ".png"}
                                                                                     alt={x.name}/>
                                                                                <div className="content">
                                                                                    <div
                                                                                        className="header">{x.name}</div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                        {x.name !== this.state.data.name && (
                                                                            <a className="item"
                                                                               href={"/database/" + safeName(x.name)}>
                                                                                <img className="ui avatar image"
                                                                                     onError={this.addDefaultSrc}
                                                                                     src={"/assets/images/items/thumbs/" + safeName(x.name) + ".png"}
                                                                                     alt={x.name}/>
                                                                                <div className="content">
                                                                                    <div
                                                                                        className="header">{x.name}</div>
                                                                                </div>
                                                                            </a>
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </details>
                                                    </>
                                                ))}

                                                <div className="ui segment">
                                                    <h3>Planting {this.state.data.name.replace("Seed Bag", "Seeds")}</h3>
                                                    <p>{this.state.data.name.replace("Seed Bag", "seeds")} can be
                                                        planted in the ground, by using <kbd>Left click</kbd> whilst
                                                        holding a seed bag, on any of your owned plots of land. Once
                                                        placed, they will start to grow from sapling up to a fully
                                                        grown {this.state.data.name.replace("Seed Bag", "plant")}.</p>
                                                    <p>Plants have 10 stages of growth, growing one stage each minute.
                                                        After 5 stages, you will notice the grass on the ground
                                                        disappears.
                                                        After a further 5 stages, for a total of 10 stages your plant
                                                        is fully grown.</p>
                                                </div>

                                                <div className="ui segment">
                                                    <h3>The {this.state.data.name.replace("Seed Bag", "Plant")}</h3>
                                                    <img
                                                        src={"/assets/images/items/plants/" + safeName(this.state.data.name.replace("Seed Bag", "Plant")) + ".jpg"}
                                                        alt={this.state.data.name.replace("Seed Bag", "Plant")}
                                                        className="ui bordered centered medium rounded image"/>
                                                </div>

                                                <div className="ui segment">
                                                    <h3>Harvesting {this.state.data.name.replace("Seed Bag", " Plants")} </h3>
                                                    <p>Once your {this.state.data.name.replace("Seed Bag", "Plant")}
                                                        &nbsp;has grown 5 stages, you can harvest it using
                                                        a <ItemTooltip
                                                            item="pickaxe"/>,
                                                        doing so will yield 2x the {this.state.data.name} you planted,
                                                        ready for replanting.
                                                    </p>
                                                    <p><em>Note - if you harvest the plant before the grass has gone,
                                                        you
                                                        will only receive 1x {this.state.data.name}.</em></p>
                                                </div>
                                                <div className="ui segment">
                                                    <h3>Other seed bags</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_seedbags.map(item => (
                                                            <>
                                                                {item === this.state.data.name && (
                                                                    <div className="item active">
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item !== this.state.data.name && (
                                                                    <a className="item"
                                                                       href={"/database/" + safeName(item)}>
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </a>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {(grp_paintable_items.includes(this.state.data.name)) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>Other walls</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_paintable_items.map(item => (
                                                            <>
                                                                {item === this.state.data.name && (
                                                                    <div className="item active">
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item !== this.state.data.name && (
                                                                    <a className="item"
                                                                       href={"/database/" + safeName(item)}>
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </a>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {(grp_paint_bucket_items.includes(this.state.data.name)) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>Painting items</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_paint_bucket_items.map(item => (
                                                            <>
                                                                {item === this.state.data.name && (
                                                                    <div className="item active">
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item !== this.state.data.name && (
                                                                    <a className="item"
                                                                       href={"/database/" + safeName(item)}>
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </a>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {grp_machines.includes(this.state.data.name) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>Other machines</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_machines.map(item => (
                                                            <>
                                                                {item === this.state.data.name && (
                                                                    <div className="item active">
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item !== this.state.data.name && (
                                                                    <a className="item"
                                                                       href={"/database/" + safeName(item)}>
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </a>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {grp_diggables.includes(this.state.data.name) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>Drop chances</h3>
                                                    <ResourceChanceTable/>
                                                </div>

                                                <div className="ui segment">
                                                    <h3>Other resource items</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_diggables.map(item => (
                                                            <>
                                                                {item === this.state.data.name && (
                                                                    <div className="item active">
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item !== this.state.data.name && (
                                                                    <a className="item"
                                                                       href={"/database/" + safeName(item)}>
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </a>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {grp_fishing_items.includes(this.state.data.name) && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>Fishing related items</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_fishing_items.map(item => (
                                                            <>
                                                                {item === this.state.data.name && (
                                                                    <div className="item active">
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item !== this.state.data.name && (
                                                                    <a className="item"
                                                                       href={"/database/" + safeName(item)}>
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </a>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {(grp_crafted_items.includes(this.state.data.name) || this.state.data.name === "Anvil") && (
                                            <>
                                                <div className="ui segment">
                                                    <h3>Craftable Items</h3>
                                                    <div className="ui horizontal link list">
                                                        {grp_crafted_items.map(item => (
                                                            <>
                                                                {item === this.state.data.name && (
                                                                    <div className="item active">
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {item !== this.state.data.name && (
                                                                    <a className="item"
                                                                       href={"/database/" + safeName(item)}>
                                                                        <img className="ui avatar image"
                                                                             onError={this.addDefaultSrc}
                                                                             src={"/assets/images/items/thumbs/" + safeName(item) + ".png"}
                                                                             alt={item}/>
                                                                        <div className="content">
                                                                            <div className="header">{item}</div>
                                                                        </div>
                                                                    </a>
                                                                )}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                    <div className="sidebar five wide column">
                                        <ItemCard item={this.state.item} hide_details no_link/>

                                        <ShareButton/>

                                        <GoogleAd slot="4076766254"/>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        )
    }
}

export {mag_blueprints};