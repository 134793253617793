import React from 'react';
import {Link} from "react-router-dom";
import Header from "../Layout/Header";

export default function AppNavigation() {
    return (
        <div className="Navigation">
            <Header/>
            <div className="Navigation-main">
                <Link className="Navigation-link icon" to="/">
                    <i className="home icon"/> <span>Hydroneer Wiki Home</span>
                </Link>
                <Link className="Navigation-link icon" to="/about-hydroneer">
                    <i className="question icon"/> <span>What is Hydroneer?</span>
                </Link>
                <Link className="Navigation-link icon" to="/database">
                    <i className="database icon"/> <span>Item Database</span>
                </Link>
                <Link className="Navigation-link icon" to="/guide-book">
                    <i className="book icon"/> <span>Guide Book</span>
                </Link>
                <Link className="Navigation-link icon" to="/patch-notes">
                    <i className="bug icon"/> <span>Patch Notes</span>
                </Link>
                <Link className="Navigation-link icon" to="/speedrun">
                    <i className="stopwatch icon"/> <span>Speedrun</span>
                </Link>
                <Link className="Navigation-link icon hide-mobile" to="/faq">
                    <i className="list icon"/> <span>FAQ</span>
                </Link>
                <Link className="Navigation-link icon" to="/mods">
                    <i className="wrench icon"/> <span>Mods</span>
                </Link>
                <Link className="Navigation-link icon hide-mobile" to="/about">
                    <i className="info icon"/> <span>About</span>
                </Link>
                <Link className="Navigation-link icon only-mobile" to="/search">
                    <i className="search icon"/> <span>Search</span>
                </Link>
                <a className="Navigation-link icon hide-mobile" rel="noopener noreferrer" target="_blank"
                   href="//store.steampowered.com/app/1106840/Hydroneer/">
                    <i className="steam icon"/> <span>Buy Hydroneer</span>
                </a>
            </div>
        </div>
    )
}