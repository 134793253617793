import {Link} from "react-router-dom";
import ItemTooltip from "../../Utils/ItemTooltip";
import React from "react";

export default function ResourceChanceTable() {
    return (
        <table className="ui table basic inverted striped unstackable">
            <thead>
            <tr>
                <th colSpan="2">Resource</th>
                <th>Chance</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td width="128px">
                    <Link to="/database/iron-ore" item="iron-ore">
                        <img src="/assets/images/items/thumbs/iron-ore.png" alt="Iron Ore"/>
                    </Link>
                </td>
                <td><ItemTooltip item="iron-ore"/></td>
                <td>52%</td>
            </tr>
            <tr>
                <td width="128px">
                    <Link to="/database/gold-ore" item="gold-ore">
                        <img src="/assets/images/items/thumbs/gold-ore.png" alt="Gold Ore"/>
                    </Link>
                </td>
                <td><ItemTooltip item="gold-ore"/></td>
                <td>37%</td>
            </tr>
            <tr>
                <td width="128px">
                    <Link to="/database/uncut-emerald" item="uncut-emerald">
                        <img src="/assets/images/items/thumbs/uncut-emerald.png" alt="Uncut Emerald"/>
                    </Link>
                    <Link to="/database/uncut-sapphire" item="uncut-sapphire">
                        <img src="/assets/images/items/thumbs/uncut-sapphire.png" alt="Uncut Sapphire"/>
                    </Link>
                    <Link to="/database/uncut-ruby" item="uncut-ruby">
                        <img src="/assets/images/items/thumbs/uncut-ruby.png" alt="Uncut Ruby"/>
                    </Link>
                </td>
                <td><ItemTooltip item="uncut-emerald"/>, <ItemTooltip item="uncut-sapphire"/>, <ItemTooltip
                    item="uncut-ruby"/>
                </td>
                <td>5%</td>
            </tr>
            <tr>
                <td width="128px">
                    <Link to="/database/shard" item="shard">
                        <img src="/assets/images/items/thumbs/shard.png" alt="Shard"/>
                    </Link>
                </td>
                <td><ItemTooltip item="shard"/></td>
                <td>5%</td>
            </tr>
            <tr>
                <td width="128px">
                    <Link to="/database/cloutium-ore" item="cloutium-ore">
                        <img src="/assets/images/items/thumbs/cloutium-ore.png" alt="Cloutium Ore"/>
                    </Link>
                </td>
                <td><ItemTooltip item="cloutium-ore"/></td>
                <td>1%</td>
            </tr>
            </tbody>
        </table>
    )
}