import React from 'react';
import ReactHtmlParser from "react-html-parser";
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";

export default class PatchNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            active_patch: props.match.params.patch_id || null,
        }
    }

    async componentWillMount() {
        const url = API_URL + "/api/patchnotes/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false});
    }

    render() {
        return (
            <div className="ui container">
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            <div className="ui breadcrumb inverted">
                                <a href="/" className="section">Bridgepour Wiki Home</a>
                                <i className="right angle icon divider"/>
                                <div className="active section">Official Hydroneer Patch Notes</div>
                            </div>

                            {this.state.active_patch ?
                                (
                                    <>
                                        <br/>
                                        <a href="/patch-notes/">View all patch notes</a>
                                        {this.state.data.map((data) => (
                                            <>
                                                {this.state.active_patch === data.id.toString() && (
                                                    <details className="ui segment" key={data.summary}
                                                             open={this.state.active_patch === data.id.toString() && true}>
                                                        <summary>
                                                            {data.summary}
                                                        </summary>
                                                        <>
                                                            {ReactHtmlParser(data.details)}
                                                        </>
                                                    </details>
                                                )}
                                            </>
                                        ))}
                                    </>
                                ) : (
                                    this.state.data.map((data) => (
                                        <details className="ui segment" key={data.summary} >
                                            <summary>
                                                {data.summary}
                                            </summary>
                                            <>
                                                {ReactHtmlParser(data.details)}
                                            </>
                                        </details>
                                    ))
                                )
                            }
                        </>
                    )
                }
            </div>
        )
    }
}