import React from 'react';

export default function HydroLogic_d() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book" className="section">Guide Book</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book/guide/hydro-logic" className="section">HydroLogic</a>
                <i className="right angle icon divider"/>
                <div className="active section">II. Logic elements continued</div>
            </div>

            <div className="Page-header">
                <h1>
                    II. Logic elements continued
                </h1>
            </div>

            <div className="ui segment">
                <h3>b. Logic gates</h3>
                <p>Very good, you made it to here!</p>
                <p>But don't get too satisfied with yourself, that was the easy part.</p>
                <p>You can create very interesting things with logic cables, but if you want something actually useful,
                    you will need <strong>logic gates</strong>.</p>
                <p>Don't worry, it's not hard, it just requires a bit more attention.</p>
                <p>The first thing you should know, is that all the logic gates are 'T' shaped cables.</p>
                <p>And they have the same kind of feature as the <span className="orange">diode</span> cable, with two
                    inputs and one output.</p>
                <p>That being said, let's dive into it!</p>
                <p>We will begin with a simple one, the <span className="orange">compare</span> gate:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>You can see the two inputs, 'A' and 'B', and the output at the top.</p>
                <p>That gate will output a '1' signal if both 'A' and 'B' received the same value.</p>
                <p>Once again, a schematic will help:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>As you can see, 'A' already received a '4' value earlier. As you should now know, that value will
                    stay here. We will then send a '4' value to 'B':</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>The output fires a '1' signal!</p>
                <p>Now let's see what happens if we send a different value to 'B', '2' for example.</p>
                <p>Nothing happened, the output still has the '1' value from earlier.</p>
                <p><span className="red">AND THAT IS CRUCIAL!!!</span></p>
                <p>A very common mistake is to think that the <span className="orange">compare</span> gate will send a
                    signal each time it receives something on an input. <span className="red">IT DOESN'T!!</span></p>
                <p>The <span className="orange">compare</span> gate will only output a '1' signal when both input are
                    the same.</p>
                <p><span className="red">Nothing more</span>.</p>
                <p>Now that you are aware of that <strong>VERY IMPORTANT</strong> concept (ok, i'll calm down), we can
                    talk about the <span className="orange">bigger than</span> gate:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>It is very similar to the <span className="orange">compare</span> gate.</p>
                <p>The output will send a '1' value if the 'A' value is bigger than the 'B' value (hence the name).</p>
                <p>Do you really need a schematic?</p>
                <p>Okay, but just because it's you:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>Okay that was the hard part.</p>
                <p>The following gates have a more intuitive behavior.</p>
                <p>The easier one, is the <span className="orange">add</span> gate :</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>It will output a signal with a value that equals to 'A' + 'B'.</p>
                <p>The convenient thing is that 'A' + 'B' is the same thing as 'B' + 'A', so it doesn't really matters
                    how you connect the inputs.</p>
                <p>No schematic for this one, I will need space for the last gate.</p>
                <p>And here it is, the <span className="orange">substract</span> gate:</p>
                <p>It's very similar to the <span className="orange">add</span> gate, it will output the value 'A' -
                    'B'.</p>
                <p>Except this time you have to be careful, because 'A' - 'B' is NOT the same as 'B' - 'A'.</p>
                <p>See for yourself:</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>Inputs are 4 (on 'A') and 2 (on 'B').</p>
                <p>Output is 2.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
                <p>Let's swap 'A' and 'B'.</p>
                <p>Inputs are 4 (on 'B') and 2 (on 'A').</p>
                <p>Output is -2.</p>
                <img src="http://placehold.it/450x100" alt="placeholder" />
            </div>

            <a className="ui brand button" href="/guide-book/guide/hydro-logic/logic-elements/"><i
                className="left arrow icon"/> Back</a>
            <a className="ui brand button right floated" href="/guide-book/guide/hydro-logic/logic-elements-part-three/">Logic elements (part 3))<i className="right arrow icon"/></a>
            <br/>
        </div>
    )
}