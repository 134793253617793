import React from 'react';

export default function SmithingCrafting() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <a href="/guide-book" className="section">Guide Book</a>
                <i className="right angle icon divider"/>
                <div className="active section">Smithing and Crafting</div>
            </div>

            <div className="Page-header">
                <h1>
                    Smithing and Crafting
                    <div className="sub-heading">Now you know how to dig for resources, learn how to refine those and make more money!</div>
                </h1>
            </div>

            <div className="ui segment">
                <h2>Hey you, you're finally awake.</h2>
                <p>When you first arrive in the world of Hydroneer, you will be dropped off at the <u>Ember
                    Cradle</u> digsite, near the town of <u>Burnscroft</u>.</p>
                <p>Gracefully perched on the hill, you'll see the infamous <u>Bastion Keep</u> fortification!</p>
            </div>

            <a className="ui brand button" href="/guide-book/guide/getting-started"><i className="left arrow icon"/> Getting Started</a>
            <button disabled className="ui brand button right floated disabled">Learn how to research blueprints <i className="right arrow icon" /></button>
        </div>
    )
}