export function safeName(item) {
    return item.replace(/\s+/g, '-').replace(/,/g, "").replace(/'/g, "").toLowerCase();
}

export function numberWithSuffix(number) {
    let num = number.toString();
    if (num.endsWith("11")) return num + "th";
    if (num.endsWith("12")) return num + "th";
    if (num.endsWith("13")) return num + "th";
    if (num.endsWith("1")) return num + "st";
    if (num.endsWith("2")) return num + "nd";
    if (num.endsWith("3")) return num + "rd";
    return num + "th";
}