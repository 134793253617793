import React from 'react';
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import ReactHtmlParser from "react-html-parser";

export default class About extends React.Component {
    state = {
        loading: true,
        data: null
    }

    async componentDidMount() {
        const url = API_URL + "/api/releasenotes/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})
    }

    render() {
        return (
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">About Bridgepour Wiki</div>
                </div>

                <div className="ui segment">
                    <p>Bridgepour.com was founded by a guy with the peculiar name of <strong>ItchyBeard</strong> on various
                        socials.</p>
                    <p>I spent many hours watching various streamers and youtubers playing Hydroneer, and finally caved in
                        and spent £7.19 and met one of the best game community groups. I felt the desire to give something
                        back to that community that have been nothing but helpful and warming, not only to myself, but to
                        all newcomers to the community.</p>
                    <p>When I first started playing Hydroneer, I had a little idea of what I needed to do due to watching
                        countless hours of lets plays, I knew I had to keep visiting the biggest town in the game to stock
                        up on building supplies and machinery.</p>
                    <p>But there was just... <strong>something</strong> missing.. A hole that needed filling.. </p>
                    <h3>Welcome to Bridgepour Wiki (the Hydroneer Wiki!)</h3>
                    <p>Bridgepour Wiki is <strong title="only one as far as I am aware!">the</strong> (un)official, purpose built
                        fansite of the game Hydroneer (this is the Hydroneer Wiki) and is aimed to act as a "tour guide", bestow
                        important knowledge, tips'n'tricks and provide useful tools to help players of all skill levels.
                    </p>
                    <p>Bridgepour Wiki is not affiliated with Foulball Hangover, the developer of Hydroneer. This
                        is a passion project. If you want to support the developers and you don't already own the game,
                        please go and and purchase it on Steam using the links in the main menu. After all, that's the
                        reason this fansite exists! You'll notice there are a handful of adverts around the this website,
                        this is to help with maintenance and hosting costs, any ad revenue received will go directly back
                        into helping Bridgepour Wiki be more awesome. If you feel like supporting me directly you can &nbsp;
                        <a target="_blank" rel="noopener noreferrer" href="https://www.buymeacoffee.com/ItchyBeard"><span role="img" aria-label="Drink">🥤</span> <span>Buy me a Monster</span></a>, any donations are greatly appreciated &lt;3!
                    </p>
                    <p className="ib">
                        ItchyBeard
                    </p>
                </div>

                <div className="ui segment">
                    <h3>Special Thanks and Contributors</h3>
                    <p>The people mentioned below have helped Bridgepour become what it is today.</p>
                    <p>- Max "grandmaMax" Hayon - Hydroneer Developer</p>
                    <p>- Heilos (Team Hydronix) - Contributor</p>
                    <p>- Sasha Windred - Contributor</p>
                    <p>- nhatanh0475 - Contributor</p>
                </div>

                <h3>Bridgepour Update history</h3>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader />
                    ) : (
                        <>
                            {this.state.data.map((data, index) => (
                                <div className="ui segment" key={data.title}>
                                    <details open={0 === index}>
                                        <summary>
                                            {data.title} &emsp;
                                            <small className="muted">({data.date})</small>
                                        </summary>
                                        <>
                                            <br/>
                                            {ReactHtmlParser(data.content)}
                                        </>
                                    </details>
                                </div>
                            ))}
                        </>
                    )
                }
            </div>
        )
    }
}