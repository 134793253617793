import React from 'react';


export default class ModDetails extends React.Component {
    render() {
        return (
            <div className="ui container">
                <div className="ui breadcrumb inverted">
                    <a href="/" className="section">Bridgepour Wiki Home</a>
                    <i className="right angle icon divider"/>
                    <a href="/mods/" className="section">Mods</a>
                    <i className="right angle icon divider"/>
                    <a href="/legacy-mods/" className="section">Legacy Mods</a>
                    <i className="right angle icon divider"/>
                    <div className="active section">Hydrotility++</div>
                </div>

                <div className="Page-header">
                    <h1>
                        Hydrotility++
                        <div className="sub-heading">
                            <span className="muted">By: Gamerkuipers</span><br/>
                            Allows you to change/freeze time. And gives Quit to desktop! Allows the Possibility to use modules (see below)
                        </div>
                    </h1>
                </div>

                <div className="ui two column grid stackable">
                    <div className="column">
                        <div className="ui segment">
                        <h2>Keybinds</h2>
                        <ul>
                            <li><strong>Hydrotility++</strong>
                                <ul>
                                    <li><kbd>ESC</kbd> : Open Menu</li>
                                </ul>
                            </li>
                            <li><strong>Movement Module</strong>
                                    <ul>
                                        <li><kbd>F</kbd> : Toggle Fly</li>
                                        <li><kbd>SPACE (Jump)</kbd> : Fly Up</li>
                                        <li><kbd>C (Crouch)</kbd> : Fly Down</li>
                                        <li><kbd>LeftSHIFT</kbd> : Sprint (modifier also works on fly)</li>
                                        <li><kbd>Ctrl+F1</kbd> : Toggle Modifiers</li>
                                    </ul>
                                </li>
                                <li><strong>Collision Module</strong>
                                    <ul>
                                        <li><kbd>G</kbd> : Toggle Collision</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="column">
                        <div className="ui segment">
                            <h2>Features</h2>
                            <ul>
                                <li>Quit to desktop</li>
                                <li>Change time</li>
                                <li>Freeze time</li>
                                <li>Read Coordinates Location</li>
                                <li>Add modules to the esc menu</li>
                            </ul>
                            <a href="https://github.com/Gamerkuipers/Hydroneer-Modding/raw/main/Hydrotility++/500-Hydrotility++_P.pak" className="ui button brand block"><i className="download icon" /> Download Hydrotility++</a>
                            <a href="https://github.com/Gamerkuipers/Hydroneer-Modding/raw/main/Hydrotility++/Hydrotility++_Complete.zip" className="ui button brand block"><i className="download icon" /> Download Hydrotility++ and Modules (.zip)</a>

                            <h2>Required mods</h2>
                            <ul>
                                <li><a href="https://github.com/RHlNO/HydroneerModding/raw/main/Release%20Mods/501-HydroUMH_P.pak">HydroUMH</a></li>
                            </ul>
                        </div>
                    </div>
                </div>



                    <h2>Modules</h2>
                    <div className="ui two columns stackable grid homepage-boxes">
                        <div className="column">
                            <div className="ui segment">
                                <h2 className="ui center aligned icon header">
                                    <i className="circular walking icon"/> Movement
                                </h2>
                                <p>Adds Fly and movement modifiers (Also requires HydroUMH)</p>
                                <a href="https://github.com/Gamerkuipers/Hydroneer-Modding/raw/main/Hydrotility++/Modules/500-ModuleMovement_P.pak" className="ui button brand block fluid"><i className="download icon" /> Download</a>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui segment">
                                <h2 className="ui center aligned icon header">
                                    <i className="circular car crash icon"/> Collisions
                                </h2>
                                <p>Adds Collision toggles (Also requires HydroUMH)</p>
                                <a href="https://github.com/Gamerkuipers/Hydroneer-Modding/raw/main/Hydrotility++/Modules/500-ModuleCollisions_P.pak" className="ui button brand block fluid"><i className="download icon" /> Download</a>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui segment">
                                <h2 className="ui center aligned icon header">
                                    <i className="circular hand sparkles icon"/> Teleports
                                </h2>
                                <p>Adds Collision toggles (Also requires HydroUMH)</p>
                                <a href="https://github.com/Gamerkuipers/Hydroneer-Modding/raw/main/Hydrotility++/Modules/500-ModuleTeleport_P.pak" className="ui button brand block fluid"><i className="download icon" /> Download</a>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui segment">
                                <h2 className="ui center aligned icon header">
                                    <i className="circular toggle on icon"/> Toggles
                                </h2>
                                <p>Adds Toggles such as lock all buildings and turn on drills (Also requires HydroUMH)</p>
                                <a href="https://github.com/Gamerkuipers/Hydroneer-Modding/raw/main/Hydrotility++/Modules/500-ModuleToggles_P.pak" className="ui button brand block fluid"><i className="download icon" /> Download</a>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui segment">
                                <h2 className="ui center aligned icon header">
                                    <i className="circular compass icon" /> ItemMover
                                </h2>
                                <p>For modders, giving them easier access to placing items into the world (Also requires HydroUMH)</p>
                                <a href="https://github.com/Gamerkuipers/Hydroneer-Modding/raw/main/Hydrotility++/Modules/500-ModuleItemMover_P.pak" className="ui button brand block fluid"><i className="download icon" /> Download</a>
                            </div>
                        </div>
                    </div>

                <div className="ui segment">
                    <h2>Screenshots</h2><h3>Main UI</h3><p>
                        <img alt="Main UI"
                             src="https://raw.githubusercontent.com/Gamerkuipers/Hydroneer-Modding/main/Hydrotility++/img/Hydrotility++_Main.png"/>
                    </p><h3>Module Collisions</h3>
                    <p><img alt="Module Collisions"
                            src="https://raw.githubusercontent.com/Gamerkuipers/Hydroneer-Modding/main/Hydrotility++/img/Hydrotility++_Collisions.png"/>
                    </p>
                    <h3>Module Movement</h3><p><img alt="Module Movement"
                                                    src="https://raw.githubusercontent.com/Gamerkuipers/Hydroneer-Modding/main/Hydrotility++/img/Hydrotility++_Movement.png"/>
                    </p>
                    <h3>Module Toggles</h3><p><img alt="Module Toggles"
                                                   src="https://raw.githubusercontent.com/Gamerkuipers/Hydroneer-Modding/main/Hydrotility++/img/Hydrotility++_Toggles.png"/>
                    </p><h3>Module
                        Teleports</h3><p><img alt="Module Teleports"
                                              src="https://raw.githubusercontent.com/Gamerkuipers/Hydroneer-Modding/main/Hydrotility++/img/Hydrotility++_Teleports.png"/>
                    </p><h3>Module
                    ItemMover</h3><p><img alt="Module ItemMover"
                                          src="https://github.com/Gamerkuipers/Hydroneer-Modding/raw/main/Hydrotility%2B%2B/img/Hydrotility++_ItemMover.png"/>
                    </p>
                </div>
            </div>
        )
    }
}