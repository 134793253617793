import React from 'react';
import GoogleAd from "../Components/GoogleAd";

export default class ModsFAQ extends React.Component {
    render() {
        return (
            <>
                <div className="ui container">
                    <div className="ui breadcrumb inverted">
                        <a href="/" className="section">Bridgepour Wiki Home</a>
                        <i className="right angle icon divider"/>
                        <a href="/mods/" className="section">Mods</a>
                        <i className="right angle icon divider"/>
                        <div className="active section">Mods FAQ</div>
                    </div>

                    <div className="Page-header">
                        <h1>
                            Hydroneer Mods FAQ
                            <div className="sub-heading">Helpful information on mods for Hydroneer.</div>
                        </h1>
                    </div>

                    <div className="ui two column doubling grid container">
                        <div className="eleven wide column">
                            <div className="ui segment">
                                <h2>Installing Mods Manually</h2>
                                <ol>
                                    <li><strong>Hydroneer must be closed before mods can be installed.</strong></li>
                                    <li>Find the mod you want to install from the list on the <a href="/legacy-mods/">Legacy Mods</a> page.</li>
                                    <li>Clicking "Download" will take you to a page with the latest version of the mod.</li>
                                    <li>From the next page click the "Download", on the right side.</li>
                                    <li>Once you have downloaded your desired mod file, press the <kbd>Windows</kbd> key and <kbd>R</kbd> together to bring up the Run Command Window.</li>
                                    <li>Copy and paste the following into the Run Command Window; <kbd>%LocalAppData%\Mining\Saved</kbd>, and press <kbd>Enter</kbd>.</li>
                                    <li>If you have already installed Hydroneer mods before, skip step 8, otherwise continue.</li>
                                    <li>You will need to create a <strong>"Paks"</strong> folder (note capitalization and spelling is important). This is where you will put the mods.</li>
                                    <li>Cut or copy the mod file you downloaded, and paste it inside the "Paks" folder.</li>
                                    <li>The mod is now installed and ready for use. Launch Hydroneer and the mod should function.
                                    </li>
                                </ol>
                            </div>

                            <div className="ui segment">
                                <h1>How to clear your Mod folders</h1>
                                <p>Sometimes mods can break Hydroneer. Please follow <strong>both</strong> sets of instructions to ensure all mods are removed.</p>

                                <h3>Step 1: Steam Workshop Mods</h3>
                                <p>• Open the Run prompt by holding the WINDOWS key and pressing R.</p>
                                <p>• Type the line below into the box (or copy/paste) then hit ENTER and delete the contents:</p>
                                {/* eslint-disable-next-line no-octal-escape */}
                                <p><kbd>%ProgramFiles(x86)%\Steam\steamapps\workshop\content\1106840</kbd></p>
                                <p>NOTE: Sometimes the Steam Workshop folder isn't there for Hydroneer. If the folder cannot be found, then you do not need to worry about this step, please continue.</p>

                                <h3>Step 2: Manually Installed (Legacy) Mods</h3>
                                <p>• Open the Run prompt by holding the WINDOWS key and pressing R.</p>
                                <p>• Type the line below into the box (or copy/paste) then hit ENTER and delete the contents:</p>
                                <p><kbd>%localappdata%\Mining\Saved\Paks</kbd></p>
                            </div>
                        </div>
                        <div className="sidebar five wide column">
                            <a href="/mods" className="ui brand button fluid">Steam Workshop Mods</a><br/>
                            <a href="/legacy-mods" className="ui brand button fluid">Legacy Mods</a><br/>
                            <span className="ui brand button fluid disabled">View the Mods FAQ</span>

                            <GoogleAd slot="5601721053"/>
                            <br/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}