import React from 'react'
import {MapContainer, TileLayer, Marker, Popup} from "react-leaflet";
import './Map.sass';
import {Link} from "react-router-dom";
import {digsiteIcon, stockMarketIcon, jewelerIcon, storeIcon} from './pins/Pins';

const map_coords = {
    digsite_ember_cradle: [73, -114],
    digsite_mildews_aquifer: [72, 75],
    digsite_dreck_quarry: [30, 119],
    digsite_south_hope: [-30, 38],
    stock_market_bastion_keep: [69, -154],
    stock_market_burnscroft: [51, -108],
    stock_market_ironil: [71, -27],
    jeweler_bastion_keep: [61.5, -158],
    jeweler_bridgepour: [51, -40],
    rig_parts_bridgepour: [53, -30],
    vehicle_store_bridgepour: [46, -2],
    tools_equipment_bridgepour: [49, -26],
    scrapyard_bridgepour: [50, -11],
    conveyor_nation_bridgepour: [49, -20],
    housing_store_bridgepour: [43, -30],
    power_logic_bridgepour: [35, -37],
    itchy_carts_bridgepour: [36, -8],
    fobricks_bridgepour: [43, -23],
    barge_bridgepour: [6, -30],
    repair_store_burnscroft: [51, -98],
    tools_equipment_bastion_keep: [62.5, -129.8],
    top_left_boundary: [85, -180],
    bottom_right_boundary: [-85, 180]
}


export default class WorldMap extends React.Component {
    state = {
        lat: 0,
        lng: 0,
        zoom: this.props.zoom || 1,
        coords: this.props.coords || [0,0]
    }

    render() {
        return (
            <MapContainer center={this.state.coords} zoom={this.state.zoom} style={{height: "512px"}} minZoom={1}
                 maxZoom={3} maxBounds={[map_coords.top_left_boundary, map_coords.bottom_right_boundary]}>
                <TileLayer
                    url="/assets/images/map/{z}/{x}/{y}.jpg"
                    noWrap={true}
                />

                {/* DIGSITES */}
                <Marker position={map_coords.digsite_ember_cradle} icon={digsiteIcon}>
                    <Popup>
                        <Link to="/guide-book/digsites">Ember Cradle</Link>
                    </Popup>
                </Marker>
                <Marker position={map_coords.digsite_mildews_aquifer} icon={digsiteIcon}>
                    <Popup>
                        <Link to="/guide-book/digsites">Mildew's Aquifer</Link>
                    </Popup>
                </Marker>
                <Marker position={map_coords.digsite_dreck_quarry} icon={digsiteIcon}>
                    <Popup>
                        <Link to="/guide-book/digsites">Dreck Quarry</Link>
                    </Popup>
                </Marker>
                <Marker position={map_coords.digsite_south_hope} icon={digsiteIcon}>
                    <Popup>
                        <Link to="/guide-book/digsites">South Hope</Link>
                    </Popup>
                </Marker>

                {/* STOCK MARKETS */}
                <Marker position={map_coords.stock_market_bastion_keep} icon={stockMarketIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bastion-keep/stock-market/0">Stock Market</Link>
                        <br/>
                        Bastion Keep
                    </Popup>
                </Marker>
                <Marker position={map_coords.stock_market_burnscroft} icon={stockMarketIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/burnscroft/stock-market/3">Stock Market</Link>
                        <br/>
                        Burnscroft
                    </Popup>
                </Marker>
                <Marker position={map_coords.stock_market_ironil} icon={stockMarketIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/ironil/stock-market/5">Stock Market</Link>
                        <br/>
                        Ironil
                    </Popup>
                </Marker>

                {/* JEWELERS */}
                <Marker position={map_coords.jeweler_bastion_keep} icon={jewelerIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bastion-keep/jeweler/1">Jeweler</Link>
                        <br/>
                        Bastion Keep
                    </Popup>
                </Marker>
                <Marker position={map_coords.jeweler_bridgepour} icon={jewelerIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bridgepour/jeweler/10">Jeweler</Link>
                        <br/>
                        Bridgepour
                    </Popup>
                </Marker>
                <Marker position={map_coords.rig_parts_bridgepour} icon={storeIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bridgepour/rig-parts/12">Rig Parts</Link> (RIP)
                        <br/>
                        Bridgepour
                    </Popup>
                </Marker>
                <Marker position={map_coords.scrapyard_bridgepour} icon={storeIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bridgepour/scrapyard/6">Scrap Yard</Link>
                        <br/>
                        Bridgepour
                    </Popup>
                </Marker>
                <Marker position={map_coords.vehicle_store_bridgepour} icon={storeIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bridgepour/vehicle-store/13">Vehicle Store</Link>
                        <br/>
                        Bridgepour
                    </Popup>
                </Marker>
                <Marker position={map_coords.tools_equipment_bridgepour} icon={storeIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bridgepour/tools-equipment/7">Tools & Equipment</Link>
                        <br/>
                        Bridgepour
                    </Popup>
                </Marker>
                <Marker position={map_coords.conveyor_nation_bridgepour} icon={storeIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bridgepour/conveyor-nation/11">Conveyor Nation</Link>
                        <br/>
                        Bridgepour
                    </Popup>
                </Marker>
                <Marker position={map_coords.housing_store_bridgepour} icon={storeIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bridgepour/housing/8">Housing</Link>
                        <br/>
                        Bridgepour
                    </Popup>
                </Marker>
                <Marker position={map_coords.fobricks_bridgepour} icon={storeIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bridgepour/fancy-furniture/9">Fobricks Fancy Stuff</Link>
                        <br/>
                        Bridgepour
                    </Popup>
                </Marker>
                <Marker position={map_coords.barge_bridgepour} icon={storeIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bridgepour/the-barge/14">The Barge</Link>
                        <br/>
                        Bridgepour
                    </Popup>
                </Marker>
                <Marker position={map_coords.repair_store_burnscroft} icon={storeIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/burnscroft/repair/4">Repair Store</Link>
                        <br/>
                        Burnscroft
                    </Popup>
                </Marker>
                <Marker position={map_coords.tools_equipment_bastion_keep} icon={storeIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bastion-keep/tools-equipment/2">Tools & Equipment</Link>
                        <br/>
                        Bastion Keep
                    </Popup>
                </Marker>
                <Marker position={map_coords.power_logic_bridgepour} icon={storeIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bridgepour/hobsons-powerworks/16">Hobson's Powerworks</Link>
                        <br/>
                        Bridgepour
                    </Popup>
                </Marker>
                <Marker position={map_coords.itchy_carts_bridgepour} icon={storeIcon}>
                    <Popup>
                        <Link to="/guide-book/towns/bridgepour/itchy-carts/17">Itchy Carts</Link>
                        <br/>
                        Bridgepour
                    </Popup>
                </Marker>
            </MapContainer>
        )
    }
}