import React from "react";
import ReactHtmlParser from "react-html-parser";

const faq_items = [
    ["Can I run Hydroneer / What are Hydroneers minimum system requirements?",
    "The minimum requirements for you to run Hydroneer are listed on the Steam store page and below for reference:<br/>" +
    "<strong>OS</strong>: Windows 10<br/>" +
    "<strong>Processor</strong>: Quad-Core 64-bit Intel or AMD processor, 2.4 GHZ CPU<br/>" +
    "<strong>Memory</strong>: 8 GB RAM<br/>" +
    "<strong>Graphics</strong>: 1 GB DirectX 11 dedicated video card<br/>" +
    "<strong>Storage</strong>: 6 GB available space"],
    ["Can I play Hydroneer on my games console; Xbox, PS4 or Switch?", "At the moment, Hydroneer is solely playable on PC. The developer has stated a console launch <strong>may</strong> be considered after all main updates are out."],
    ["Can I play Hydroneer on my handheld device; Android, iPhone, iPad or Switch?", "At the moment, Hydroneer is solely playable on PC. It is highly unlikely that mobile devices would have enough computing power to run Hydroneer.."],
    ["Can I play Hydroneer on Mac or Linux?", "At the moment, Hydroneer is solely playable on PC and as such has not yet found its' way on to Mac or Linux operating systems. The game may run well on Proton for Linux, but isn't officially supported."],
    ["When did Hydroneer release for PC?", "Hydroneer officially released on Steam for PC on May 8th, 2020."],
    ["Where can I buy Hydroneer, how much does Hydroneer cost?", "Hydroneer is purchasable directly from Steam, you <a href='https://store.steampowered.com/app/1106840/Hydroneer/' target='_blank'>view the Steam Store page for Hydroneer</a>. At the time of writing (October 6, 2020) Hydroneer costs £7.19 / $9.99"],
    ["Why can't I sell my items in Hydroneer", "Make sure you're at a Jeweler or Stock Market, with your items placed on the table. Then hit the button on the price board, using <kbd>Left click</kbd>."],
    ["Why can't I buy items in Hydroneer", "All stores have a buy platform. Make sure the items you want to purchase are on this platform. Then hit the button on the price board, using <kbd>Left click</kbd>. Make sure you have enough money in the bucket too!"],
    ["How do I rotate items in Hydroneer", "Pick up the item using <kbd>E</kbd> then <kbd>Left click</kbd> to enter place mode. When in place mode, you can use the keys <kbd>R</kbd>, <kbd>T</kbd> and <kbd>Y</kbd> to rotate on the Z, X and Y axis respectively."],
    ["How do I place items more freely / place items with more precision?", "Pick up the item using <kbd>E</kbd> then hold <kbd>E</kbd> to enter precision place mode. This allows you to place the item about 4-5 feet in front of the player, rather than dropping by your feet."],
    ["Hydroneer free download?", "Unfortunately there is no Hydroneer free download, if you like the game please consider supporting small game developers, please purchase the game."],
    ["Hydroneer free to play?", "Hydroneer is not free to play, if you like the game please consider supporting small game developers, please purchase the game."],
    ["Hydroneer free updates?", "If you've purchased Hydroneer through the Steam store, then you are entitled to all future updates for free!"]
]

export default class FAQ extends React.Component {
    render() {
        return (
            <>
                <div className="ui container">

                    <div className="Page-header">
                        <h1>
                            Hydroneer FAQ
                            <div className="sub-heading">Hydroneers' top frequently asked questions.</div>
                        </h1>
                    </div>

                    <div className="ui segment">
                        <p>Hydroneer Multiplayer is coming! Check out the <a href="/mp-faq">Multiplayer FAQ Here</a>!</p>

                        <div className="ui inverted divided items">
                            {faq_items.map(item => (
                                <>
                                    <div className="item">
                                        <div className="content">
                                            <p className="header">{item[0]}</p>
                                            <div className="description">{ReactHtmlParser(item[1])}</div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}