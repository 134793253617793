import React from "react";

import {API_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";
import ItemTooltip from "../../Utils/ItemTooltip";

export default class VehicleList extends React.Component {
    constructor({props}) {
        super(props);
        this.state = {
            data: null,
            loading: true
        }
    }

    async componentDidMount() {
        const url = API_URL + `/api/vehicles/`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({
            data: data.results,
            loading: false
        })
    }

    render() {
        return (
            <>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            {this.state.data.map(vehicle => (
                                <div className="ui segment" key={vehicle.id}>
                                    <div className="ui two column doubling grid container">
                                        <div className="eight wide column">
                                            <h3>{vehicle.name}</h3>
                                            <p>You can purchase this vehicle for {(vehicle.price / 100).toLocaleString(undefined, {maximumFractionDigits: 0})} <ItemTooltip item="hydrocoins" />.</p>

                                            <h3>Usage</h3>
                                            <p>Use <kbd>Q</kbd> to get in and out of the {vehicle.name}</p>

                                            {vehicle.name === "Hydro Excavator" ? (
                                                <>
                                                    <p>The Hydro Excavator is used to dig up lots of dirt at once. Hold <kbd>Left Click</kbd> and move the mouse up/down to move the arm up or down. Hold <kbd>Right Click</kbd> to enable the rotary arm.</p>
                                                    <p>When the excavator bed is full, you will still be able to dig up dirt, but it will be lost in the process. To empty the bed, simply move to the location you wish to unload the dirt, and hold <kbd>E</kbd> - this will shoot the dirt chunks out the left side of the truck.</p>

                                                    <p>This vehicle requires water to operate, you can find the tank on the right side along with a water level gauge.</p>
                                                </>
                                            ) : (
                                                <>
                                                    <p>The {vehicle.name} is used to move items and objects around the world.</p>
                                                    <p>This vehicle requires water to operate, you can find the tank on the top of the cab along with a water level gauge on the right side.</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="sidebar eight wide column">
                                            <table className="ui table basic inverted striped unstackable">
                                                <tbody>
                                                <tr>
                                                    <td width="30%">Acceleration</td>
                                                    <td>
                                                        <progress value={vehicle.stats.acceleration} max="10" style={{width: "100%"}}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Top Speed</td>
                                                    <td>
                                                        <progress value={vehicle.stats["top-speed"]} max="10" style={{width: "100%"}}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Handling</td>
                                                    <td>
                                                        <progress value={vehicle.stats.handling} max="10" style={{width: "100%"}}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Braking</td>
                                                    <td>
                                                        <progress value={vehicle.stats.braking} max="10" style={{width: "100%"}}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Storage Capacity</td>
                                                    <td>
                                                        <progress value={vehicle.stats["storage-capacity"]} max="10" style={{width: "100%"}}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Dirt Capacity</td>
                                                    <td>
                                                        <progress value={vehicle.stats["dirt-capacity"]} max="10" style={{width: "100%"}}/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Weight</td>
                                                    <td>
                                                        <progress value={vehicle.stats.weight} max="10" style={{width: "100%"}}/>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )
                }
            </>
        )
    }
}