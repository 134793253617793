import React from 'react';
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import DataTable, { createTheme } from 'react-data-table-component';

createTheme('bridgepour', {
    text: {
        primary: '#e5e0dd',
        secondary: '#efebe8',
    },
    background: {
        default: 'rgba(0,0,0,0.1)',
    },
    divider: {
        default: 'rgba(0,0,0,0.2)',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
});

const columns = [
    {
        name: 'Rank',
        selector: 'rank',
        sortable: true,
    },
    {
        name: 'Username',
        selector: 'username',
        sortable: true
    },
    {
        name: 'Pokes',
        selector: 'pokes',
        sortable: true,
        right: true,
    },
];

export default class PokesLeaderboard extends React.Component {
    state = {
        loading: true,
        data: null,
        filteredData: null
    }

    async componentDidMount() {
        let url = API_URL + "/api/pokes/";
        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})
    }

    async handleChange(e) {
        this.setState({loading: true})

        let url = API_URL + "/api/pokes";
        if (e.target.checked) {
            url = API_URL + "/api/pokes/all";
        }

        const response = await fetch(url);
        const data = await response.json();
        this.setState({data: data.results, loading: false})
    }

    render() {
        return (<>
                <div className="ui container">
                    <div className="ui breadcrumb inverted">
                        <a href="/" className="section">Bridgepour Wiki Home</a>
                        <i className="right angle icon divider"/>
                        <div className="active section">Pokes</div>
                    </div>

                    <div className="Page-header">
                        <h1>
                            Discord Poke Leaderboard
                            <div className="sub-heading">Participate in the Poke Leaderboard over on the Discord server for a chance to get in on the 2.0 (Multiplayer) Beta! Check your pokes in the #bot-channel using the <kbd>!bp mypokes</kbd> command!</div>
                        </h1>
                    </div>

                    <div className="text-right" style={{paddingBottom: "2ch"}}>
                        <div className="ui checkbox inverted">
                            <input type="checkbox" id="showAll" name="showAll" onChange={e => this.handleChange(e)}/>
                            <label for="showAll">Show All?</label>
                        </div>
                    </div>

                    {this.state.loading || !this.state.data ?
                        (
                            <Loader/>
                        ) : (
                            <div className="poke-list">
                                <DataTable
                                    columns={columns}
                                    data={this.state.data}
                                    theme="bridgepour"
                                    pagination
                                    paginationPerPage="50"
                                    dense
                                />
                            </div>
                        )
                    }
                </div>
            </>
        )
    }
}