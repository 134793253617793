import React from "react";
import ItemCard from "./ItemCard";
import ItemTooltip from "../../Utils/ItemTooltip";
import {API_URL} from "../../Utils/Config";
import Loader from "../../Utils/Loader";
import {mag_blueprints} from "../../Pages/DatabaseView";

const bp_cloutium_items = ["battle-axe", "blunderbuss", "cage", "water-elbow-pipe", "water-pipe", "shield", "water-t-pipe"]

export default class CraftingIngredients extends React.Component {
    constructor({props, item}) {
        super(props);
        this.state = {
            item: item,
            data: null,
            loading: true
        }
    }

    async componentDidMount() {
        const url = API_URL + `/api/resources/${this.state.item}/`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({
            data: data.results[0],
            loading: false
        })
    }

    render() {
        return (
            <>
                {this.state.loading || !this.state.data ?
                    (
                        <Loader/>
                    ) : (
                        <>
                            {(["scrap-metal", "scrap-bar"]).includes(this.state.data.name) && (
                                <>
                                    <div className="ui segment">
                                        <p>Scrap Metal is obtained by either selling construction items to the <a
                                            href="/guide-book/towns/bridgepour/scrapyard/6">Scrap Yard</a> in
                                            Bridgepour, or by blowing construction items up using <ItemTooltip
                                                item="logic-explosives"/></p>
                                        <p>Scrap Metal is worth 50% of the original construction items value.</p>
                                        <p>This can be increased further by smelting Scrap Metal in a <ItemTooltip
                                            item="small-furnace"/>, <ItemTooltip item="smelter"/> or a <ItemTooltip
                                            item="logic-smelter"/>. This yields a <ItemTooltip item="scrap-bar"/> which
                                            is worth 20% more than the Scrap Metal, bringing the total return to 60% of
                                            the original construction items' value.</p>
                                    </div>
                                </>
                            )}

                            {this.state.data.type === "ore" && (
                                <>
                                    <div className="ui segment">
                                        <p>This item can be smelted in a <ItemTooltip
                                            item="small-furnace"/>, <ItemTooltip item="smelter"/> or a <ItemTooltip
                                            item="logic-smelter"/> to make the
                                            following items.</p>
                                        <div className="ui link three cards stackable">
                                            {this.state.data.output.map(x => (
                                                <>
                                                    <ItemCard key={x} item={x}/>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}

                            {this.state.data.type === "bar" && (
                                <>
                                    <div className="ui segment">
                                        <p>This item is made by smelting the following items in a <ItemTooltip
                                            item="small-furnace"/>, <ItemTooltip item="smelter"/> or a <ItemTooltip
                                            item="logic-smelter"/>.</p>
                                        <div className="ui link three cards stackable">
                                            {this.state.data.input.map(item => (
                                                <ItemCard key={item.name} item={item.name} amt={item.amt}/>
                                            ))}
                                        </div>
                                    </div>
                                    {this.state.data.name !== "cloutium-bar" ? (
                                        <>
                                            {this.state.data.name !== "scrap-bar" && (
                                                <div className="ui segment">
                                                    <p>This item is used when forging the following items.</p>
                                                    <div className="ui link three cards">
                                                        {this.state.data.output.map(x => (
                                                            <>
                                                                <ItemCard key={x} item={x}/>
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="ui segment">
                                            <p>This item is used when engineering the following items on a <ItemTooltip
                                                item="workbench"/>.</p>
                                            <div className="ui link three cards">
                                                {bp_cloutium_items.map(x => (
                                                    <>
                                                        <ItemCard key={x} item={x}/>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {this.state.data.type === "uncut-gem" && (
                                <>
                                    <div className="ui segment">
                                        <p>This item is used when crafting the following items.</p>
                                        <div className="ui link three cards stackable">
                                            {this.state.data.output.map(x => (
                                                <>
                                                    <ItemCard key={x} item={x}/>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}

                            {this.state.data.type === "gem" && (
                                <>
                                    <div className="ui segment">
                                        <p>The following items are used to craft the this item</p>
                                        <div className="ui link three cards stackable">
                                            {this.state.data.input.map(item => (
                                                <ItemCard key={item.name} item={item.name} amt={item.amt}/>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="ui segment">
                                        <p>This item is used when crafting the following items.</p>
                                        <div className="ui link three cards stackable">
                                            {(this.state.data.output.map(y => (
                                                <ItemCard key={y} item={y}/>
                                            )))}
                                        </div>
                                    </div>
                                </>
                            )}

                            {this.state.data.type === "composite-gem" && (
                                <>
                                    <div className="ui segment">
                                        <p>Any amount of the following items can compressed, using a <ItemTooltip
                                            item="compressor"/>, to create this item.</p>
                                        <div className="ui link three cards stackable">
                                            {this.state.data.input.map(item => (
                                                <ItemCard key={item.name} item={item.name} amt={item.amt}/>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="ui segment">
                                        <p>This item is used when crafting the following items.</p>
                                        <div className="ui link three cards stackable">
                                            {(this.state.data.output.map(y => (
                                                <ItemCard key={y} item={y}/>
                                            )))}
                                        </div>
                                    </div>
                                </>
                            )}

                            {this.state.data.type === "jewelry" && (
                                <>
                                    <div className="ui segment">
                                        <h3>How to craft the {this.state.data.name}</h3>
                                        <p>To craft the {this.state.data.name} you need to heat up the required gold
                                            bars (shown below) using a <ItemTooltip item="small-furnace"/>. Whilst your
                                            bars are heating up, select the corresponding recipe on the <ItemTooltip
                                                item="anvil"/>. Once your bars are hot, drop them on the top of the
                                            Anvil
                                            {["emerald-ring", "emerald-necklace", "sapphire-ring", "sapphire-necklace", "ruby-ring", "ruby-necklace"].includes(this.state.data.name) && (
                                                <>, along with the required gems (shown below),</>
                                            )}&nbsp;and hit the Anvil with your <ItemTooltip
                                                item="blacksmithing-hammer"/>.
                                        </p>
                                        <p>The following items are used to craft the this item.</p>
                                        {["emerald-ring", "emerald-necklace", "sapphire-ring", "sapphire-necklace", "ruby-ring", "ruby-necklace"].includes(this.state.data.name) && (
                                            <>
                                                <div className="ui message inverted">
                                                    Note - You can swap the gem for the composite variant, for more info
                                                    on Composite Gems check out the <ItemTooltip
                                                    item="compressor"/> page.
                                                </div>
                                                <br/>
                                            </>
                                        )}
                                        <div className="ui link three cards stacked">
                                            {this.state.data.input.map(item => (
                                                <ItemCard key={item.name} item={item.name} amt={item.amt}/>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}

                            {this.state.data.type === "weapon" && (
                                <>
                                    <div className="ui segment">
                                        <h3>How to forge the {this.state.data.name}</h3>
                                        <p>To forge the {this.state.data.name} you need to heat up the required iron
                                            bars (shown below) using a <ItemTooltip item="small-furnace"/>. Whilst your
                                            bars are heating up, select the corresponding recipe on the <ItemTooltip
                                                item="anvil"/>. Once your bars are hot, drop them on the top of the
                                            Anvil and hit the Anvil with your <ItemTooltip item="blacksmithing-hammer"/>.
                                        </p>
                                        <p>The following items are used to forge the this item.</p>
                                        <div className="ui link three cards stackable">
                                            {this.state.data.input.map(item => (
                                                <ItemCard key={item.name} item={item.name} amt={item.amt}/>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}

                            {this.state.data.type === "blueprint" && (
                                <>
                                    {mag_blueprints.filter(x => x.name === (this.state.data.name.replace("-", " ").replace(/\w\S*/g, function (txt) {
                                        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                                    }) + " Blueprint")).map(bp => (
                                        <>
                                            <div className="ui segment">
                                                <h3>How to Learn</h3>
                                                <p>This item is created via the following blueprint, click the card to
                                                    learn how to obtain the blueprint.</p>
                                                <div className="ui link three cards stackable">
                                                    <ItemCard key={this.state.data.name + "-blueprint"}
                                                              item={this.state.data.name + "-blueprint"}/>
                                                </div>
                                            </div>
                                            <div className="ui segment">
                                                <h3>How to craft</h3>
                                                <p>To craft this item you need to place the <ItemTooltip
                                                    item={this.state.data.name + "-blueprint"}/> on the left side of
                                                    the <ItemTooltip item="workbench"/> and a <strong>hot</strong>&nbsp;
                                                    <ItemTooltip item={bp.craft_bar}/> (with a minimum weight
                                                    of {bp.min_craft_weight}) on the right side of the bench. Then use
                                                    the <ItemTooltip item="screwdriver"/> on the bench and
                                                    the {this.state.data.name.replace(" Blueprint", "")} will appear.
                                                </p>
                                            </div>
                                        </>
                                    ))}
                                </>
                            )}
                        </>
                    )
                }
            </>
        )
    }
}