import React from 'react';

export default class ScrollToTop extends React.Component {
    constructor() {
        super();
        this.state = {
            intervalId: 0
        };
    }

    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        this.setState({intervalId: intervalId});
    }

    render() {
        return (
            <button title="Back to top" className="ui button mini brown icon top-link" onClick={() => {
                this.scrollToTop();
            }}>
                BACK TO TOP <i className="up triangle icon"/>
            </button>
        );
    }
}