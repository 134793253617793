import React from 'react';
import ReactHtmlParser from "react-html-parser";
import Moment from 'react-moment';
import './Blog.sass';
import {Link} from "react-router-dom";

export default class PostSummary extends React.Component {
    render() {
        return (
            <div className="Blog-post ui segment">
                {this.props.post.thumbnail !== "" ? (
                    <div className="Blog-post-image">
                        <img src={"/assets/images/blog/" + this.props.post.thumbnail} alt={this.props.post.title + " | The Hydroneer Wiki"} title={this.props.post.title + " | The Hydroneer Wiki"} />
                    </div>
                ):(<div />)}
                <div className="Blog-post-content">
                    <h3>
                        <Link to={"/blog/" + this.props.post.id} post_id={this.props.post.id}>{this.props.post.title}</Link>
                    </h3>
                    <Moment fromNow date={this.props.post.date} utc withTitle titleFormat="dddd, DD MMMM YYYY HH:mm" className="Blog-post-timestamp" />
                    <p>{ ReactHtmlParser (this.props.post.html_summary) }</p>
                </div>
            </div>
        )
    }
}