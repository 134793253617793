import React from 'react';
import {Helmet} from "react-helmet";
import {Switch} from "react-router";
import {Route, BrowserRouter as Router, Redirect} from "react-router-dom";
import './App.sass';
import ReactGA from 'react-ga';
import Footer from "../Layout/Footer";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Navigation from "../Pages/Navigation";
import PatchNotes from "../Pages/PatchNotes";
import MaxEgg from "../Pages/MaxEgg";
import Database from "../Pages/Database";
import DatabaseView from "../Pages/DatabaseView";
import Mods from "../Pages/Mods";
import Guidebook from "../Pages/Guidebook";
import BlogPost from "../Components/Blog/BlogPost";
import GuidebookDigsites from "../Components/Guidebook/GuidebookDigsites";
import ShopView from "../Components/Guidebook/ShopView";
import GuidebookTowns from "../Components/Guidebook/GuidebookTowns";
import AboutHydroneer from "../Pages/AboutHydroneer";
import Privacy from "../Pages/Privacy";
import Contact from "../Pages/Contact";
import GuideFirstTimer from "../Pages/Guides/FirstTimer";
import GuideGettingStarted from "../Pages/Guides/GettingStarted";
import {API_URL} from "../Utils/Config";
import Loader from "../Utils/Loader";
import Search from "../Pages/Search";
import WorkshopMods from "../Pages/WorkshopMods";
import RentAPlot from "../Pages/RentAPlot";
import ModDetails from "../Pages/ModDetails";
import SubHeader from "../Layout/SubHeader";
import List from "../Pages/List";
import ShoppingList from "../Pages/ShoppingList";
import FAQ from "../Pages/FAQ";
import SmithingCrafting from "../Pages/Guides/SmithingCrafting";
import Speedrun from "../Pages/Speedrun";
import MPFAQ from "../Pages/MP-FAQ";
import HydroLogic_a from "../Pages/Guides/HydroLogic/HydroLogic_Landing";
import HydroLogic_b from "../Pages/Guides/HydroLogic/HydroLogic_LogicBehaviour";
import HydroLogic_c from "../Pages/Guides/HydroLogic/HydroLogic_LogicElements";
import HydroLogic_d from "../Pages/Guides/HydroLogic/HydroLogic_LogicElementsContinued";
import HydroLogic_e from "../Pages/Guides/HydroLogic/HydroLogic_LogicElementsContinuedAgain";
import HydroLogic_f from "../Pages/Guides/HydroLogic/HydroLogic_BuildExamples";
import ModsFAQ from "../Pages/ModsFAQ";
import PokesLeaderboard from "../Pages/PokesLeaderboard";
import BlogAll from "../Components/Blog/BlogAll";

ReactGA.initialize('UA-17616511-3');
ReactGA.pageview(window.location.pathname + window.location.search);

const error = () => (
    <div className="error-page">
        <h1>4<img src="/assets/images/items/cloutium-ore.png" alt="0" height="128px"/>4</h1>
        <p>Your shovel fell under the world.</p>
        <a href="/">Go back to the homepage</a>
    </div>
);

class RedirectLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            url: null,
            query: props.match.params.search_query
        }
    }

    async componentDidMount() {
        const item_url = API_URL + `/api/items/exact/${this.state.query}/`;
        const item_response = await fetch(item_url);
        const item_data = await item_response.json();

        const shop_url = API_URL + `/api/shops/exact/${this.state.query}/`;
        const shop_response = await fetch(shop_url);
        const shop_data = await shop_response.json();

        const town_url = API_URL + `/api/towns/exact/${this.state.query}/`;
        const town_response = await fetch(town_url);
        const town_data = await town_response.json();

        const digsite_url = API_URL + `/api/digsites/exact/${this.state.query}/`;
        const digsite_response = await fetch(digsite_url);
        const digsite_data = await digsite_response.json();

        const vehicle_url = API_URL + `/api/vehicles/exact/${this.state.query}/`;
        const vehicle_response = await fetch(vehicle_url);
        const vehicle_data = await vehicle_response.json();

        let url = "/"

        if (item_data.results.length >= 1) {
            url = `/database/${this.state.query}`
        }

        if (shop_data.results.length >= 1) {
            url = `/guide-book/towns/${shop_data.results[0].town.town_safe_name}/${shop_data.results[0].safe_name}/${shop_data.results[0].id}`
        }

        if (town_data.results.length >= 1) {
            url = `/guide-book/towns/`
        }

        if (digsite_data.results.length >= 1) {
            url = `/guide-book/digsites/`
        }

        if (vehicle_data.results.length >= 1) {
            url = `/guide-book/towns/bridgepour/vehicle-store/13`
        }

        this.setState({
            loading: false,
            url: url
        })
    }

    render() {
        return (
            <div className="ui container">
                {this.state.loading ?
                    (
                        <Loader/>
                    ) : (
                        <Redirect push to={this.state.url} />
                    )
                }
            </div>
        )
    }
}


function App() {
    return (
        <Router>
            <Helmet>
                <title>Bridgepour | Official Hydroneer Wiki | Tips, Guides, Calculators, Item Database and more!</title>
                <meta name="description" content="Bridgepour is a fan made wiki for the game Hydroneer, the Hydroneer Wiki. Bridgepour wiki aims to take the role of a tour guide to help players, both new and old, make their time within the world of Hydroneer less of a rush and more of an ease." />
            </Helmet>
            <div className="Page">
                <div className="Container">
                    <SubHeader />
                    <div className="Content">
                        <Switch>
                            <Route path='/' exact component={Home}/>
                            <Route path='/search' exact component={Search}/>
                            <Route path='/about' component={About}/>
                            <Route path='/mod-faq' component={ModsFAQ}/>
                            <Route path='/mp-faq' component={MPFAQ}/>
                            <Route path='/faq' component={FAQ}/>
                            <Route path='/rent-a-plot' component={RentAPlot}/>
                            <Route path='/speedrun' component={Speedrun}/>
                            <Route path='/list/' exact component={ShoppingList}/>
                            <Route path='/list/:encoded_list/:list_name?' component={List}/>
                            <Route path='/link/:search_query' component={RedirectLink}/>
                            <Route path='/about-hydroneer' component={AboutHydroneer}/>
                            <Route path="/patch-notes/:patch_id?" component={PatchNotes}/>
                            <Route path='/blog/' exact component={BlogAll}/>
                            <Route path='/blog/:post_id' component={BlogPost}/>
                            <Route path='/database/:item' component={DatabaseView}/>
                            <Route path='/database' exact component={Database}/>
                            <Route path='/max' exact component={MaxEgg}/>
                            <Route path='/pokes' exact component={PokesLeaderboard}/>
                            <Route path="/mods" component={WorkshopMods}/>
                            <Route path="/legacy-mods/Hydrotility++" component={ModDetails}/>
                            <Route path="/legacy-mods" component={Mods}/>
                            <Route path="/guide-book" exact component={Guidebook}/>
                            <Route path="/guide-book/digsites" component={GuidebookDigsites}/>
                            <Route path="/guide-book/towns" exact component={GuidebookTowns}/>
                            <Route path="/guide-book/towns/:town_safe_name/:shop_safe_name/:shop_id"
                                   component={ShopView}/>
                            <Route path="/guide-book/guide/first-timer" component={GuideFirstTimer}/>
                            <Route path="/guide-book/guide/getting-started" component={GuideGettingStarted}/>
                            <Route path="/guide-book/guide/smithing-crafting" component={SmithingCrafting}/>
                            <Route path="/guide-book/guide/hydro-logic" exact component={HydroLogic_a}/>
                            <Route path="/guide-book/guide/hydro-logic/logic-behaviour" component={HydroLogic_b}/>
                            <Route path="/guide-book/guide/hydro-logic/logic-elements" component={HydroLogic_c}/>
                            <Route path="/guide-book/guide/hydro-logic/logic-elements-part-two" component={HydroLogic_d}/>
                            <Route path="/guide-book/guide/hydro-logic/logic-elements-part-three" component={HydroLogic_e}/>
                            <Route path="/guide-book/guide/hydro-logic/build-examples" component={HydroLogic_f}/>
                            <Route path='/privacy' component={Privacy}/>
                            <Route path='/contact' component={Contact}/>
                            <Route component={error}/>
                        </Switch>
                    </div>
                    <Footer/>
                </div>
            </div>
            <Navigation/>

        </Router>
    );
}

export default App;
