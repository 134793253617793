import React from 'react';

export default function Privacy() {
    return (
        <div className="ui container">
            <div className="ui breadcrumb inverted">
                <a href="/" className="section">Bridgepour Wiki Home</a>
                <i className="right angle icon divider"/>
                <div className="active section">Privacy Notice</div>
            </div>

            <div className="Page-header">
                <h1>
                    Privacy Notice
                    <div className="sub-heading">Last updated August 14, 2020</div>
                </h1>
            </div>

            <div className="ui segment">
                <p> Thank you for choosing to be part of our community at Bridgepour ( " <strong> Company </strong> ", "
                    <strong> we </strong> ", " <strong> us </strong> ", or " <strong> our </strong> "). We are committed
                    to
                    protecting your personal information and your right to privacy. If you have any questions or
                    concerns
                    about this
                    privacy notice, or our practices with regards to your personal information, please contact us at <a
                        href="mailto:contact@bridgepour.com">contact@bridgepour.com</a>. </p>
                <p> When you visit our website <a href="https://bridgepour.com/"> bridgepour.com </a> (the
                    " <strong>
                        Website </strong> "), and more generaally, use any of our services (the
                    " <strong> Services </strong> ",
                    which include the Website), we appreciate that you are trusting us with your personal information.
                    We
                    take your
                    privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way
                    possible
                    what
                    information we collect, how we use it and what rights you have in relation to it. We hope you take
                    some
                    time to
                    read through it carefully, as it is important. If there are any terms in this privacy notice that
                    you do
                    not
                    agree with, please discontinue use of our Services immediately. </p>
                <p> This privacy notice applies to all information collected through our Services (which, as described
                    above,
                    includes our Website), as well as any related services, sales, marketing or events. </p>
                <p><strong> Please read this privacy notice carefully as it will help you understand what we do with the
                    information that we collect. </strong></p>

                <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
                <p>Information automatically collected</p>
                <p><em> <strong> In Short: </strong> Some information &mdash; such as your Internet Protocol (IP)
                    address
                    and/or
                    browser and device characteristics &mdash; is collected automatically when you visit our Website.
                </em>
                </p>
                <p> We automatically collect certain information when you visit, use or navigate the Website. This
                    information does
                    not reveal your specific identity (like your name or contact information) but may include device and
                    usage
                    information, such as your IP address, browser and device characteristics, operating system, language
                    preferences, referring URLs, device name, country, location, information about who and when you use
                    our
                    Website
                    and other technical information. This information is primarily needed to maintain the security and
                    operation of
                    our Website, and for our internal analytics and reporting purposes. </p>
                <p> Like many businesses, we also collect information through cookies and similar technologies. </p>
                <p> The information we collect includes: </p>
                <ul>
                    <li><em> Log and Usage Data. </em> Log and usage data is service-related, diagnostic usage and
                        performance
                        information our servers automatically collect when you access or use our Website and which we
                        record
                        in log
                        files. Depending on how you interact with us, this log data may include your IP address, device
                        information,
                        browser type and settings and information about your activity in the Website (such as the
                        date/time
                        stamps
                        associated with your usage, pages and files viewed, searches and other actions you take such as
                        which
                        features you use), device event information (such as system activity, error reports (sometimes
                        called 'crash
                        dumps') and hardware settings).
                    </li>
                </ul>
                <ul>
                    <li><em> Device Data. </em> We collect device data such as information about your computer, phone,
                        tablet or
                        other device you use to access the Website. Depending on the device used, this device data may
                        include
                        information such as your IP address (or proxy server), device application identification
                        numbers,
                        location,
                        browser type, hardware model Internet service provider and/or mobile carrier, operating system
                        configuration
                        information.
                    </li>
                </ul>
                <ul>
                    <li><em> Location Data. </em> We collect information data such as information about your device's
                        location,
                        which can be either precise or imprecise. How much information we collect depends on the type of
                        settings of
                        the device you use to access the Website. For example, we may use GPS and other technologies to
                        collect
                        geolocation data that tells us your current location (based on your IP address). You can opt out
                        of
                        allowing
                        us to collect this information either by refusing access to the information or by disabling your
                        Locations
                        settings on your device. Note however, if you choose to opt out, you may not be able to use
                        certain
                        aspects
                        of the Services.
                    </li>
                </ul>

                <h2>2. WILL YOUR INFORMATION BE SHARED WITH ANYONE? </h2>
                <p><strong> <em> In Short: </em> </strong> <em> We only share information with your consent, to comply
                    with
                    laws,
                    to provide you with services, to protect your rights, or to fulfill business obligations. </em></p>
                <p> We may process or share your data that we hold based on the following legal basis: </p>
                <ul>
                    <li><strong> Consent: </strong> We may process your data if you have given us specific consent to
                        use
                        your
                        personal information in a specific purpose. <br/> <br/></li>
                    <li><strong> Legitimate Interests: </strong> We may process your data when it is reasonably
                        necessary to
                        achieve our legitimate business interests. <br/> <br/></li>
                    <li><strong> Performance of a Contract: </strong> Where we have entered into a contract with you, we
                        may
                        process your personal information to fulfill the terms of our contract. <br/> <br/></li>
                    <li><strong> Legal Obligations: </strong> We may disclose your information where we are legally
                        required
                        to do
                        so in order to comply with applicable law, governmental requests, a judicial proceeding, court
                        order, or
                        legal process, such as in response to a court order or a subpoena (including in response to
                        public
                        authorities to meet national security or law enforcement requirements). <br/> <br/></li>
                    <li><strong> Vital Interests: </strong> We may disclose your information where we believe it is
                        necessary to
                        investigate, prevent, or take action regarding potential violations of our policies, suspected
                        fraud,
                        situations involving potential threats to the safety of any person and illegal activities, or as
                        evidence in
                        litigation in which we are involved.
                    </li>
                </ul>
                <p> More specifically, we may need to process your data or share your personal information in the
                    following
                    situations: </p>
                <ul>
                    <li><strong> Business Transfers. </strong> We may share or transfer your information in connection
                        with,
                        or
                        during negotiations of, any merger, sale of company assets, financing, or acquisition of all or
                        a
                        portion of
                        our business to another company.
                    </li>
                </ul>

                <h2>3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? </h2>
                <p><strong> <em> In Short: </em> </strong> <em> We may use cookies and other tracking technologies to
                    collect and
                    store your information. </em></p>
                <p> We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
                    store
                    information. Specific information about how we use such technologies and how you can refuse certain
                    cookies is
                    set out in our Cookie Notice. </p>

                <h2>4. WHAT ARE YOUR PRIVACY RIGHTS? </h2>
                <p><strong> <em> In Short: </em> </strong> <em> You may review, change, or terminate your account at any
                    time.
                </em></p>
                <p> If you are resident in the European Economic Area and you believe we are unlawfully processing your
                    personal
                    information, you also have the right to complain to your local data protection supervisory
                    authority.
                    You can
                    find their contact details here: <a
                        href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                        http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm </a> . </p>
                <p> If you are resident in Switzerland, the contact details for the data protection authorities are
                    available here:
                    <a href="https://www.edoeb.admin.ch/edoeb/en/home.html"> https://www.edoeb.admin.ch/edoeb/en/home.html </a> .
                </p>
                <p><strong> <u> Cookies and similar technologies: </u> </strong> Most Web browsers are set to accept
                    cookies
                    by
                    default. If you prefer, you can usually choose to set your browser to remove cookies and to reject
                    cookies. If
                    you choose to remove cookies or reject cookies, this could affect certain features or services of
                    our
                    Website.
                    To opt-out of interest-based advertising by advertisers on our Website visit <a
                        href="http://www.aboutads.info/choices/"> http://www.aboutads.info/choices/ </a> . </p>

                <h2>5. CONTROLS FOR DO-NOT-TRACK FEATURES </h2>
                <p> Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (
                    "DNT ")
                    feature or setting you can activate to signal your privacy preference not to have data about your
                    online
                    browsing activities monitored and collected. At this stage, no uniform technology standard for
                    recognizing and
                    implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser
                    signals
                    or any
                    other mechanism that automatically communicates your choice not to be tracked online. If a standard
                    for
                    online
                    tracking is adopted that we must follow in the future, we will inform you about that practice in a
                    revised
                    version of this privacy notice. </p>

                <h2>6. DO WE MAKE UPDATES TO THIS NOTICE? </h2>
                <p><strong> <em> In Short: </em> </strong> <em> Yes, we will update this notice as necessary to stay
                    compliant with
                    relevant laws. </em></p>
                <p> We may update this privacy notice from time to time. The updated version will be indicated by an
                    updated
                    "Revised " date and the updated version will be effective as soon as it is accessible. If we make
                    material
                    changes to this privacy notice, we may notify you either by prominently posting a notice of such
                    changes
                    or by
                    directly sending you a notification. We encourage you to review this privacy notice frequently to be
                    informed of
                    how we are protecting your information. </p>

                <h2>7. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </h2>
                <p> If you have questions or comments about this notice, you may email us at <a
                    href="mailto:contact@bridgepour.com">contact@bridgepour.com</a></p>
            </div>
        </div>
    )
}